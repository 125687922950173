/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useSearchParams, useNavigate, useParams } from 'react-router-dom';
import { Grid } from 'react-loader-spinner';

import { GetProductDetail, GetAllAssetPermissions } from '../../services/dmpApi';
import { sendEvent } from '../../helpers/analyticsHandler';
import { Context } from '../../contexts/context';
import { Actions } from "../../contexts/action";
import { Tabs, Tab, TabPanel } from '../../components/Tabs/Tabs';

import StatefulDatatable from '../../components/datatable/StatefulDatatable';
import BackButton from '../../components/Button/BackButton/BackButton';
import Button from '../../components/Button/Button';
import ProductTile from '../../components/ProductTile/ProductTile';
import DetailDrawer from '../../components/detailDrawer/DetailDrawer';
import { Diamond, DiamondsFour } from 'phosphor-react';

import './TermDetailPage.scss'

const tagMap = {
    'NRG Enterprise': 'purple',
    'Home': 'blue',
    'Services': 'green',
    'Business': 'yellow'
};

const TermDetailPage = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState('');
    const [loadError, setLoadError] = useState('');
    const [resultsCount, setResultsCount] = useState(0);
    const {id} = useParams();
    
    const {
        state: {
            dashboardPermissions
        },
        dispatch
    } = useContext(Context);

    const [isDashboardsLoaded, setIsDashboardsLoaded] = useState(dashboardPermissions ? true : false);
    const [showModal, setShowModal] = useState(false);
    const [rowClicked, setRowClicked] = useState(-1);
    const [modalId, setModalId] = useState('');
    const [activeTab, setActiveTab] = useState(0);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [searchParams] = useSearchParams();
    const [children, setChildren]= useState([]);
    const termClickedId = (searchParams?.get('clickedId'))

    const handleChange = (e, value, id) => {
        if(activeTab===value && rowClicked===id){
            setActiveTab(0);
            setRowClicked(-1);
        }
        else {
            setActiveTab(value);
            setRowClicked(id);
        }
    };
        
    function checkDashboards(row){
        return row.original.relationships?.dashboards?.filter(d => dashboardPermissions.find(dashboard => d.id === dashboard.id))
                    .filter(d => d.status === 'Accepted' || d.status === 'reviewed').length > 0
    }

    function checkTables(row) {
        return row.original.relationships?.tables?.filter(d => dashboardPermissions.find(table => d.id === table.id))
                .filter(d => d.status === 'Accepted' || d.status==='Reviewed').length > 0
    }

    function checkDataProcesses(row) {
        return row.original.relationships?.dataProcesses?.filter(d => dashboardPermissions.find(dataProcess => d.id === dataProcess.id))
                .filter(d => d.status === 'Accepted').length > 0
    }
    
    const getWhereIsTheTermUsedTab = (row) => {
        if (checkDashboards(row) || checkTables(row) || checkDataProcesses(row)) {
            return <Tab id='glossary-page-tab-where-term' key={1} rowId={row.original.id} label="WHERE IS THE TERM USED?" value={1} caret={true}/>
        }
        return null;
    }
    const getRelatedTermsTab = (row) => {
        if (row.original.type==='Acronym' && row.original.relationships?.children?.filter(d => d.status === 'Accepted').length > 0) {
            return <Tab id='glossary-page-tab-related-term' key={2} rowId={row.original.id} label="RELATED TERMS" value={2} caret={true}/>
        }
        return null;
    }
    const getSynonymTab = (row) => {
        if (row.original.relationships?.synonyms?.filter(d => d.status === 'Accepted').length > 0) {
            return <Tab id='glossary-page-tab-synonym' key={3} rowId={row.original.id} label="SYNONYMS" value={3} caret={true}/>
        }
        return null;
    }

    const fetchData = async () => {
        const response = await GetProductDetail(id)
            .catch((err) => {
                setLoadError('Error loading product details. ' + err);
                setDataLoaded(true);
                console.log(err)
            });

        if (response) {
            const data = response.data;           
            setData(data);
            if (loadError.length > 0) setLoadError('');
            setDataLoaded(true);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        fetchData();
        if(isDashboardsLoaded && dataLoaded){
            setIsLoading(false);
        }
        if(data){
            if(id!==termClickedId){
            var tempArr = [];
            tempArr.push(...data.relationships.children.filter(d => d.status === 'Accepted').filter(d => d.id===termClickedId))
            tempArr.push(...data.relationships.children.filter(d => d.status === 'Accepted').filter(d => d.id!==termClickedId).sort((a,b) => {return a.name<b.name?-1:1}))
            setChildren(tempArr);
        }
            else{
                setChildren(data.relationships.children.filter(d => d.status === 'Accepted').sort((a,b) => {return a.name<b.name?-1:1}))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, isDashboardsLoaded, dataLoaded]);

    useEffect(() => {
        const LoadDashboards = async () => {
            const response = await GetAllAssetPermissions()
                .catch((err) => console.log(err));

            if (response) {
                const data = response.data;

                let tempDashboardPermissions = data.results.map(item => {
                    return {
                        id: item.id,
                        access: item.access,
                        appUrl: item.appUrl,
                        hasAccess: item.hasAccess
                    }
                });

                dispatch({
                    type: Actions.ADD_DASHBOARDS,
                    payload: tempDashboardPermissions
                });
               setIsDashboardsLoaded(true);
            }
        }
        if (!dashboardPermissions) {
            LoadDashboards();
        }
    }, [dispatch, dashboardPermissions]);

    function errorResult() {
        return (
            <div data-testid='search-page-error' onClick={() => fetchData()} className='loading-error'>
                <span>{loadError}<br />
                    Click here to retry</span>
            </div>
        );
    }

    const handleProductClick = (e, product) => {
        let hasAccess = false;
        let filteredDashboard = dashboardPermissions.filter(item => item.id === product.id);

        if (filteredDashboard.length > 0) {
            hasAccess = filteredDashboard[0].hasAccess;
        }

        if (hasAccess === true || product.type==='Data Science Product') {
            navigate(`/product/${product.id}`, {
                state: {
                    id: product.id,
                    name: product.name,
                    type: product.type
                }
            });
        } else {
            e.stopPropagation();
            setModalId(product.id);
            setShowModal(!showModal);
        }

        sendEvent('analyticsClickAction', 'browse_favoritesOpen', null, null, product);
    }

    function productTileFunc(product, index ) {
        return(<>
        <ProductTile
            key={index}
            id={`glossary-page-dashboard-${product.id}`}
            name={product.type === 'Database View' || product.type === 'Table' ? product.name.split(">").pop() : product.name}
            type={product.type}
            access={dashboardPermissions.filter((d) => d.id === product.id)[0]?.hasAccess}
            launchButtonAccess = {false}
            handleClick={(e) => handleProductClick(e, {
                id: product.id,
                name: product.name,
                type: product.type
            })}
            favoriteItem={product}
        />
        </>);
    }
    
    const columns = useMemo(
        () => [
            {
                Header: "Definition",
                accessor: "definition",
                // eslint-disable-next-line react/prop-types
                Cell: ({ row }) => (
                    <div className={row.original.id===termClickedId ?'term-cell-clicked':'term-cell'}> 
                        <span className='title'>{row.original.name}</span>
                        <div className='definition' dangerouslySetInnerHTML={{ __html: row.original.description }}></div>
                        <div className='tags'>
                            <span className={`chip chip--${tagMap[row.original.businessSegment] || 'grey'}`}>{row.original.businessSegment}</span>
                            <span className='type'>{row.original.type}</span>
                        </div>
                        <section className='tab'>
                            <div className='tab--container'>
                                <Tabs id='product-detail-page-tabs' selectedTab={activeTab} rowClicked={rowClicked} onChange={handleChange} className='all'>
                                    {getWhereIsTheTermUsedTab(row)}
                                    {getRelatedTermsTab(row)}
                                    {getSynonymTab(row)}
                                </Tabs>
                                <div className='grouped-terms'>
                                    {row.original.id !== id ?
                                        <Button className='btn btn-child-terms'>
                                            <div className='diamonds-four'><DiamondsFour /></div>
                                            <div> <span className='term-title'>CHILD TERM</span></div>
                                        </Button>
                                        :
                                        <Button className='btn btn-parent-terms'>
                                            <div className='diamond'><Diamond /></div>
                                            <div> <span className='term-title'>PARENT TERM</span></div>
                                        </Button>}
                                </div>
                            </div>
                            <div className={activeTab===0 ? 'tab--panel-container inactive' : 'tab--panel-container'}>
                        {rowClicked===row.original.id && 
                        <TabPanel value={activeTab} selectedIndex={1}>
                        {!isDashboardsLoaded ?
                                        <div className="loading-spinner" id="grid-loading">
                                            <Grid color="#999999" height={100} width={110} ariaLabel='loading' />
                                            <span>Loading....</span>
                                        </div>
                                        : <div className='dashboard-items'>
                                           {row.original.relationships.dashboards?.filter(d => d.status === 'Accepted' || d.status === 'Reviewed').map((dashboard, index) => {
                                                if (dashboardPermissions.filter((d) => d.id === dashboard.id)[0]) {
                                                    return (  <>{productTileFunc(dashboard, index)}</>)
                                                }
                                                else {
                                                    return (<></>)
                                                }
                                            })}
                                            {row.original.relationships.tables?.filter(d => d.status === 'Accepted' || d.status === 'Reviewed').map((table, index) => {
                                                  if (dashboardPermissions.filter((d) => d.id === table.id)[0]) {
                                                    return (  <>{productTileFunc(table, index)}</>)
                                                }
                                                else {
                                                    return (<></>)
                                                }
                                                })}
                                            {row.original.relationships.dataProcesses?.filter(d => d.status === 'Accepted').map((dataProcess, index) => {
                                                  if (dashboardPermissions.filter((d) => d.id === dataProcess.id)[0]) {
                                                    return (  <>{productTileFunc(dataProcess, index)}</>)
                                                }
                                                else {
                                                    return (<></>)
                                                }
                                            })}
                                        </div>
                                    }
                            </TabPanel>
                        }
                        {rowClicked===row.original.id && row.original.relationships?.children?.length > 0 &&
                        <TabPanel value={activeTab} selectedIndex={2}>
                        {isLoading ?
                            <div className="loading-spinner" id="grid-loading">
                                <Grid color="#999999" height={100} width={110} ariaLabel='loading' />
                                <span>Loading....</span>
                            </div>
                            : 
                            <div className='synonym-items'>                                            
                                <table >
                                    <tbody  className='glossary-page'>
                                        {row.original.relationships.children.filter(d => d.status === 'Accepted').sort((a,b) => {return a.name<b.name?-1:1}).map((relatedTerm, index) => {
                                            return(
                                                <tr key={index}  className={'synonym-row'}>
                                                <td key={index}  className='synonym-cell'>
                                                    <span className='title'>{relatedTerm.name}</span>
                                                    <div className='definition' dangerouslySetInnerHTML={{ __html: relatedTerm.description }}></div>
                                                    <div className='tags'>
                                                        <span className={`chip chip--${tagMap[relatedTerm.businessSegment] || 'grey'}`}>{relatedTerm.businessSegment}</span>
                                                        <span className='type'>{relatedTerm.type}</span>
                                                    </div>
                                                </td>
                                                </tr>    
                                            );
                                        })}    
                                    </tbody>
                                </table>        
                            </div>       
                            }
                        </TabPanel>
                        }
                        {rowClicked===row.original.id && row.original.relationships?.synonyms?.length > 0 &&
                        <TabPanel value={activeTab} selectedIndex={3}>
                        {isLoading ?
                            <div className="loading-spinner" id="grid-loading">
                                <Grid color="#999999" height={100} width={110} ariaLabel='loading' />
                                <span>Loading....</span>
                            </div>
                            : 
                            <div className='synonym-items'>                                            
                                <table >
                                    <tbody  className='glossary-page'>
                                        {row.original.relationships.synonyms.filter(d => d.status === 'Accepted').sort((a,b) => {return a.name<b.name?-1:1}).map((synonym, index) => {
                                            return(
                                                <tr key={index}  className={'test-synonym-row'}>
                                                <td key={index}  className='test-synonym-cell'>
                                                    <span className='title'>{synonym.name}</span>
                                                    <div className='definition' dangerouslySetInnerHTML={{ __html: synonym.description }}></div>
                                                    <div className='tags'>
                                                        <span className={`chip chip--${tagMap[synonym.businessSegment] || 'grey'}`}>{synonym.businessSegment}</span>
                                                        <span className='type'>{synonym.type}</span>
                                                    </div>
                                                </td>
                                                </tr>    
                                            );
                                        })}    
                                    </tbody>
                                </table>        
                            </div>       
                            }
                        </TabPanel>
                        }
                        </div>
                        </section>
                    </div>
                )
            }
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dashboardPermissions, activeTab, rowClicked]
    );
   
    function termDetailView(data) {
        return (
            <>
            <div className='back-button--glossary'>
            </div>
            <div className='parent-term--container'>
                <span className='title'>Parent Term</span>
                <div className={'content parent-term--container'}>
                    <StatefulDatatable
                    pageName='glossary-parent-term-detail'
                    id='parent-term-detail-page-datatable'
                    columns={columns}
                    data={[data]}
                    defaultPageSize={10}
                    setResultsCount={setResultsCount}
                    hasGlobalFilter={false}
                    dropDownRow={false}
                    paginationDisplay={false}
                    resultsCount = {resultsCount}
                    displayCheck = {false}
                        />
                </div>        
            </div>
            <div className='bar-grey'></div>
            <div className='child-term--container'>
            <span className='title'>Child Terms</span>
            <div className='content child-term--container'>                              
                <StatefulDatatable
                    pageName='glossary-child-term-detail'
                    id='child-term-detail-page-datatable'
                    columns={columns}
                    data={children}
                    defaultPageSize={10}
                    setResultsCount={setResultsCount}
                    hasGlobalFilter={false}
                    dropDownRow={false}
                    displayCheck={false}
                        />
                </div>        
            </div>
            </>
        )
    }

    return (
        <section data-testid='product-detail-page'>
            <div className='content--container term-detail--container'>
            <div className="header">
                <BackButton id='term-detail' />
                <div className='header--title'>
                        <h1>Glossary</h1>
                        <h2>Find definitions within the marketplace</h2>
                </div>
            </div>
            <div className='bar-blue'></div>
            <div className='data-container'>
                {isLoading &&
                    <div data-testid={'product-detail-page-loading'} className="product-detail-spinner">
                        <Grid color="#999999" height={100} width={110} ariaLabel='loading' />
                        <span>Loading....</span>
                    </div>
                }
                {!isLoading && loadError && loadError.length > 0 && errorResult()}
                {!isLoading && loadError?.length === 0 ? 
                    <>
                        <div data-testid='term-detail-page' className='term-detail-page-container'>{termDetailView(data)}</div>
                        <DetailDrawer
                            id={`glossary-page-details-${modalId}`}
                            itemId={modalId}
                            modalActive={showModal}
                            setModalState={() => setShowModal(!showModal)}
                            data-testid='glossary-page-details'
                        />
                    </>
                    : null
                }
                </div>
            </div>

        </section>
    )
}

export default TermDetailPage;