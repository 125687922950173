import React, { useEffect, useState } from 'react';
import { Grid } from 'react-loader-spinner';
import PropTypes from 'prop-types';

import Modal from './Modal';
import TextEditor from '../TextEditor/TextEditor';
import Dropdown from '../Dropdown/Dropdown';
import Button from '../Button/Button';

import './RecommendationModal.scss';

const typeMap = {
    'Dashboard product': 'Dashboard',
    'Table product': 'Table',
    'Data Science Product': 'Data Science Product',
    'Search term': 'Search Term'
}

const reverseTypeMap = {
    'Dashboard': 'Dashboard product',
    'Table': 'Table product',
    'Data Science Product': 'Data Science Product',
    'Search Term': 'Search term'
}
const RecommendationModal = ({ id, isOpen, handleClose, header, message, refreshRecommendationProducts, products, saveRecommendation, recommendationToEdit }) => {
    const [productOptions, setProductOptions] = useState(null);
    const [productSearchBy, setProductSearchBy] = useState('');
    const [isProductsLoading, setIsProductsLoading] = useState(false);
    const [recommendationType, setRecommendationType] = useState('');
    const [recommendationHtml, setRecommendationHtml] = useState('');
    const [selectedProduct, setSelectedProduct] = useState('');
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [typeDropdownError, setTypeDropdownError] = useState(false);
    const [contentError, setContentError] = useState(false);
    const [productDropdownError, setProductDropdownError] = useState(false);

    const handleProductSearch = () => {
        let tempProductOptions = products.filter(option => option.name.toLowerCase().includes(productSearchBy.toLowerCase()));
        setProductOptions(tempProductOptions);
    }

    const handleCloseModal = () => {
        handleClose();
        setRecommendationType('');
        setProductSearchBy('');
        setTypeDropdownError(false);
        setContentError(false);
        setProductDropdownError(false);
    }

    const handleRecommendationTypeChange = async (option) => {
        if (option !== recommendationType) {
            setRecommendationType(option);
            if (option !== 'Search term') {
                setIsProductsLoading(true);
                await refreshRecommendationProducts(typeMap[option])
                    .then(response => {
                        setProductSearchBy('');
                        setProductOptions(response);
                        setSelectedProduct('');
                        setIsProductsLoading(false);
                    }).catch(error => {
                        console.log(error)
                    });
            }
        }
    }

    const handleSave = async (publish) => {
        if (validateForm()) {
            setTypeDropdownError(false);
            setContentError(false);
            setProductDropdownError(false);
            setIsSaveLoading(true);
            let itemToSave = {
                "content": recommendationHtml,
                "type": typeMap[recommendationType],
                "assetId": recommendationType === 'Search term' ? '' : selectedProduct.id,
                "assetName": recommendationType === 'Search term' ? '' : selectedProduct.name,
                "isPublished": publish
            }

            if (recommendationToEdit) {
                itemToSave.id = recommendationToEdit.id
            }
            await saveRecommendation(itemToSave).then((response) => {
                setIsSaveLoading(false);
                handleCloseModal();
            }).catch((err) => {
                setIsSaveLoading(false);
                console.log(err);
            });
        }
    }

    const validateForm = () => {
        let valid = true;

        if (recommendationType === '') {
            setTypeDropdownError(true);
            valid = false
        }

        if (recommendationHtml === '' || recommendationHtml === '<p><br></p>') {
            setContentError(true);
            valid = false
        }
        
        if (recommendationType !== 'Search term' && selectedProduct === '') {
            setProductDropdownError(true);
            valid = false
        }

        return valid;
    }

    useEffect(() => {
        setProductOptions(products);
    }, [products]);

    useEffect(() => {
        setRecommendationType((recommendationToEdit ? reverseTypeMap[recommendationToEdit.type] : ''));
        setRecommendationHtml(recommendationToEdit ? recommendationToEdit.content : '');
        setSelectedProduct(recommendationToEdit && recommendationToEdit.type !== 'Search Term' 
            ? {
                name: recommendationToEdit.assetName, 
                id: recommendationToEdit.assetId
            } 
        : '');

        if (recommendationToEdit?.type !== 'Search Term' && !productOptions) {
            setIsProductsLoading(true);
        } else {
            setIsProductsLoading(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recommendationToEdit]);

    useEffect(() => {
        if(productSearchBy === '') {
            setProductOptions(products);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productSearchBy]);

    useEffect(() => {
        if (productOptions) {
            setIsProductsLoading(false);
        }
    }, [productOptions])
    
    return (
        <>
            {isOpen &&
                <Modal id={id} isOpen={isOpen} handleClose={handleCloseModal} size="md">
                    <div className='recommendation-modal'>
                        <h2>{header}</h2>
                        <p>{message}</p>
                        <div className='recommendation-content'>
                            <label>Recommendation Type</label>
                            <Dropdown
                                id="dropdown"
                                label='Select one recommendation type'
                                onChange={(option) => handleRecommendationTypeChange(option)}
                                errorMessage='This Field is required'
                                showError={typeDropdownError}
                                options={['Dashboard product', 'Data Science Product', 'Table product', 'Search term']}
                                preSelectedOption={recommendationToEdit ? recommendationType : null}
                            />
                            <label>Enter the recommendation</label>
                            <TextEditor 
                                saveHtmlFormat={setRecommendationHtml}
                                currentHtmlContent={recommendationToEdit ? recommendationToEdit.content : null}
                            />
                            {contentError && <p className='content-error'>This Field is required</p>}
                            {recommendationType && recommendationType !== 'Search term' &&
                            <>
                                <div className='product-dropdown'>
                                    <label htmlFor=''>Select Product</label>
                                    <Dropdown
                                        id="dropdown"
                                        label={{'name': 'Please choose one'}}
                                        onChange={(option) => setSelectedProduct(option)}
                                        errorMessage='This Field is required'
                                        showError={productDropdownError}
                                        options={productOptions} 
                                        searchSelect={true}
                                        setSearchTerm={setProductSearchBy}
                                        searchTerm={productSearchBy}
                                        handleSearch={handleProductSearch}
                                        isOptionsMap={true}
                                        preSelectedOption={selectedProduct}
                                        disabled={isProductsLoading}
                                    />
                                    {isProductsLoading && <span><Grid color="#999999" height={20} width={20} ariaLabel='loading' wrapperClass="modal-spinner" /></span>}
                                </div>
                                </>
                            }
                        </div>
                        <div className='recommendation-buttons'>
                            {((recommendationToEdit && !recommendationToEdit.isPublished) || !recommendationToEdit )&&
                                <Button 
                                    id={`${id}-login-btn`} 
                                    className='btn--rounded btn--blue-white btn--close'
                                    onClick={() => handleSave(false)}
                                    disabled={isSaveLoading}
                                >Save as draft</Button>
                            }           
                            <Button 
                                id={`${id}-close-btn`} 
                                className='btn--rounded btn--blue-light btn--close'
                                onClick={() => handleSave(true)}
                                disabled={isSaveLoading}
                            >Publish now</Button>
                            {isSaveLoading && <span><Grid color="#999999" height={20} width={20} ariaLabel='loading' wrapperClass="modal-spinner" /></span>}
                        </div>
                    </div>
                </Modal>
            }
        </>
    )
}

RecommendationModal.propTypes = {
    id: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    header: PropTypes.string,
    message: PropTypes.string,
    refreshRecommendationProducts: PropTypes.func,
    products: PropTypes.array,
    saveRecommendation: PropTypes.func,
    recommendationToEdit: PropTypes.any
}

export default RecommendationModal;