import React, { useRef, useState } from 'react';

import BackButton from '../../components/Button/BackButton/BackButton';
import Accordion from '../../components/Accordion/Accordion';
import AccordionItem from '../../components/Accordion/AccordionItem/AccordionItem';
import DetailCard from '../../components/DetailCard/DetailCard';
import Button from '../../components/Button/Button';
import ButtonAddMarketPlace from '../../components/Button/ButtonAddMarketPlace/ButtonAddMarketPlace';
import FAQSectionCard from '../../components/FAQSectionCard/FAQSectionCard';

import mktplaceProducts from '../../assets/icons/package.svg';
import mktplaceFeatures from '../../assets/icons/gear.svg';
import governance from '../../assets/icons/scale.svg';
import access from '../../assets/icons/key.svg';
import ownership from '../../assets/icons/trophy.svg';

import './FAQPage.scss';

const FAQPage = () => {
    const [globalClicked, setGlobalClicked] = useState(-1);

    const marketplaceProductsRef = useRef();
    const marketplaceFeaturesRef = useRef();
    const governanceRef = useRef();
    const accessProductsRef = useRef();
    const ownershipProductsRef = useRef();

    const faqSections = [
        {
            sectionName: "Marketplace Products",
            sectionIcon: mktplaceProducts,
            sectionRef: marketplaceProductsRef
        },
        {
            sectionName: "Marketplace Features",
            sectionIcon: mktplaceFeatures,
            sectionRef: marketplaceFeaturesRef
        },
        {
            sectionName: "Governance",
            sectionIcon: governance,
            sectionRef: governanceRef
        },
        {
            sectionName: "Access",
            sectionIcon: access,
            sectionRef: accessProductsRef
        },
        {
            sectionName: "Ownership",
            sectionIcon: ownership,
            sectionRef: ownershipProductsRef
        }
    ]

    const faqData = [
        {
            section: "Marketplace Products",
            question: "What is the Data Marketplace?",
            answer: "<p>The Marketplace was chartered to drive NRG’s vision of harnessing the power of data and technology by expanding data accessibility and enabling users to drive better decision-making through data insights. The Marketplace is intended to be a one-stop shop for data by giving you the ability to query and access dashboards, data tables, and data science products to help answer key business questions.</p>"
        },
        {
            section: "Marketplace Products",
            question: "What are the various products offered by the Marketplace?",
            answer: "<p>The Marketplace currently offers three types of products: </p><ol><li>Dashboards:  created in Power BI and tell a story through visualizations. The visualizations provide at-a-glance views of key performance indicators (KPIs) relevant to a particular business objective or process.</li><li>Tables: assets represent the physical tables in NRG Data Lake (SaraS).  These tables are either ingested from sourced systems or curated by Data Science or Data Engineers for Dashboards or Models. All tables have a Data Dictionary and table descriptions which consist of information that will help users quickly identify the data that is contained in the table.</li><li>Data Science Products: statistical models that use algorithms to predict future values using historical data or identify patterns or ranking. Data Science Products assist with intelligent business decisions by providing descriptive analytics to create quantitative measurement, diagnostic analytics to derive causal inference, predictive analytics to forecast future trends/events, or prescriptive analytics to generate the next best action with advanced Machine Learning models and optimization methods.</li></ol>"
        },
        {
            section: "Marketplace Products",
            question: "What is the Glossary and how is it gathered?",
            answer: "<p>The glossary is a collection of Business Terms with their unique definitions that add semantic context to data and other useful related information. The Business Terms are data elements defined with an explicit meaning for NRG Enterprise. All Business Terms are reviewed and ‘accepted’ by Data Owners and Stewards.<p>"
        },
        {
            section: "Marketplace Features",
            question: "How does search work? When I type “business” into the search bar, non-Business data appears in my search results – what is happening?​",
            answer: "<p>Currently, the search bar only searches off the data product name, product description, and definitions. For example, if you type “business” into the search bar, only data products that have the word “business” in its name, its description, or in its definitions will be returned. As more keywords are typed, search becomes tighter. If you type “business reporting” into the search bar, any data asset that has both words “business” and “reporting” will be returned.</p><p>If you want to find data with other criteria, such as owner names, domain, source systems, or product type, you can use the filters to narrow down your search. As the Data Marketplace expands, we will continue optimizing the search function by implementing a Google-like search function that allows you to ask business questions to return the right products.</p>"
        },
        {
            section: "Marketplace Features",
            question: "How does “Request for ED&A“ form Work? What can I submit within the “Request for ED&A” form? ​",
            answer: "<p>Users can request the following items from the ED&A Product Team and Data Governance team through the “Request for ED&A” form. SLA is 2 days for a response.</p><ul><li>Update or add data to an existing dashboard (Power BI report) in the Marketplace</li><li>Data tables that have not been ingested into the data lake, SaraS </li><li>New project ideas that should be considered </li><li>Other requests (i.e., surfacing dashboards developed by non-ED&A analytics groups in the Marketplace)</li> </ul> <p>If you cannot find a business function in the Marketplace, please ask your question using the form on this FAQ page instead of the “Request for ED&A” form so the ED&A project team can address your question quicker.</p>"
        },
        {
            section: "Marketplace Features",
            question: "What is a data source versus a data target in the traceability tab?​",
            answer: "<p>The data traceability tab represents the inputs and outputs of the data product. Data sources indicate the inputs used in the data product. Data targets indicate the outputs of the data product.</p>"
        },
        {
            section: "Marketplace Features",
            question: "Can I access the Marketplace on mobile devices?",
            answer: "<p>Yes, you can access the Marketplace on your phone and/or tablet. To do so, open your preferred browser, enter the Marketplace link (https://mktpl.nrg.com) and log into your NRG account to access the Marketplace. If you download the free Power BI app from your device’s app store, the Marketplace will directly open the Power BI report in the app.</p>"
        },
        {
            section: "Governance",
            question: "Where does Marketplace source its data?",
            answer: "<p>The Marketplace is sourced from Collibra, a Data Governance platform. The Collibra Data Governance Center is the single source of truth for all our data governance and stewardship activities. It is used to manage all business term definitions, acronyms, KPIs (key performance indicators). Along with supporting our data stewards, in their day-to-day activities, by providing traceability between business and technical assets, policies, and rules. It is a vital step toward achieving our vision of commonly understood consistent, trusted, and high-quality data throughout the enterprise.</p>"
        },
        {
            section: "Governance",
            question: "What does “ED&A” and “Department Sourced” mean?​ ",
            answer: "<p>Products tagged “ED&A” indicate they were developed by the Enterprise Data & Analytics team and have undergone a certification and support process to ensure data quality, verify owners, and define business terms. Products tagged “Department Sourced” were developed by employees in different departments, such as NRG Business. Department Sourced products have yet to undergo the full ED&A certification and support process.</p>"
        },
        {
            section: "Governance",
            question: "How can I give feedback or ask a question about the Marketplace?",
            answer: "<p>You can the “Get in Touch” button to the right to view the pop-up form where you can submit any questions or feedback about the Marketplace. Your question will be received by the ED&A team and you can expect an answer. If multiple users ask the same question, that question will be added to the FAQ page.</p>"
        },
        {
            section: "Access",
            question: "How long will it take to have my Dashboard, Table, or Data Science Product access request processed?",
            answer: "<p>The Service Level Agreement the Data Marketplace has with product owners is to approve or decline access requests within 72 (Business Day) Hours.</p>"
        },
        {
            section: "Access",
            question: "Why is my access to a product restricted? Why was I denied access to a product?",
            answer: "<p>Due to the necessary governance process to secure data in the Marketplace, not all products in the Marketplace are accessible to everyone. Access will absolutely be given where the business case can be made. If you do not have access to a product, you will be directed to an overview panel with a request access button. Filling out the request access form will kick-off a workflow to determine if you will receive access to the product. If the BI owner grants you access to a product, the Marketplace access will appear the next day since integration between Power Bi and the Marketplace is refreshed daily.</p>"
        },
        {
            section: "Access",
            question: "I was granted access to a product, but the Marketplace still says my access is restricted?",
            answer: "<p>PowerBI access syncs once daily with the Marketplace. If you are granted access to a PowerBI report, you will have to wait 1 day to receive access to the report through the Marketplace.</p><p>Table access syncs hourly. If you are granted access to a table, you will have to wait 1 hour to receive access to the table through the Marketplace.</p>"
        },
        {
            section: "Access",
            question: "How do I request access for a Dashboard?",
            answer: "<p>After submitting the “Request Access” form for an ED&A or Department Sourced report, you will receive an email from the Data Governance team confirming that they have received your request (SLA: 1 day). The DG team will ask the primary owner of the ED&A report for access approval; if the primary owner approves your access, the request is sent to the Power BI team (SLA: 1 day). The Power BI team will email you when your access is granted; if your access is denied, the DG team will send you an email stating why your access was denied (SLA: 2 days). If the BI owner grants you access to a product, the Marketplace access will appear the next day since integration between Power Bi and the Marketplace is refreshed daily.</p><p>If you cannot find the dashboard you need to request access to, please inquire with the individual who instructed you to obtain access for the dashboard’s Power BI lead. Email the Power BI lead who will work with you and the DG team to gain access to the dashboard.</p><p>As the Marketplace expands, we will continue optimizing the request process by integrating with ServiceNow and enabling you to see the status of your access requests in the Marketplace.</p>"
        },
        {
            section: "Access",
            question: "How do I request access for a Table?",
            answer: "<p>After submitting the “Request Access” form for a table, you will receive an email from the Data Governance team confirming that they have received your request (SLA: 1 day). The DG team will either confirm or deny your access based on your user group and access type.</p><ul><li>If your access is approved, you will be added to an AD group and receive a message from ServiceNow confirming your access (SLA: 4 - 5 days).</li><li>If your access is denied, the DG team will send you an email stating why your access was denied (SLA: 2 days).</li><li>If your access type does not exist, the DG team will work with IT to create a new AD group and access role, add you to the AD group in Azure for AWS, then send you an email with instructions on how to access SaraS-Databricks (6 – 7 days).</li></ul><p>As the Marketplace expands, we will continue optimizing the request process by integrating with ServiceNow and enabling you to see the status of your access requests in the Marketplace.</p>"
        },
        {
            section: "Access",
            question: "How do I request access for a Data Science Product?",
            answer: "<p>All users will have access to view the data science product description, presentation slides, and model description in the Marketplace. Users can submit “Request Access“ for the output table and dashboard. Please contact the Data Science Lead listed in the “Product Owners” section of the Data Science Product page if you want to know more technical details about the model or how to use it for different business applications.</p>"
        },
        {
            section: "Ownership",
            question: "What does each role next to Dashboard owner names represent? What questions can I ask each owner?​",
            answer: "<p>Each product has owners that you can contact for more context about the data product. Below are role descriptions for each owner to help you understand which owner is best suited to answer your question about a data product.</p><p>Dashboards have 2 owners: the Product Owner and the BI Lead (Business Intelligence).</p><ol><li><u>Product Owner</u>: A strategic role responsible for liaising between the product manager’s vision and the development team charged with executing that vision. Prioritizes work with business stakeholders to build out a project roadmap, which includes defining the MVP, planning and coordinating communications/demos/training to business stakeholders for upcoming releases.</li><li><u>BI (Business Intelligence) Lead</u>: Functional subject matter expert (SME) who is knowledgeable with Power BI Report/Dashboard/App. Someone who designs and/or develops BI reports, which requires them to have wide knowledge over business intelligence, data integration, data warehousing, modeling, database, and technical aspects of BI tools.</li><ol>"
        },
        {
            section: "Ownership",
            question: "What does each role next to Table owner names represent? What questions can I ask each owner?",
            answer: "<p>Each product has owners that you can contact for more context about the data product. Below are role descriptions for each owner to help you understand which owner is best suited to answer your question about a data product.</p><p>Tables have 2 owners: the Technical Lead and the Operational Steward.</p><ol><li><u>Technical Lead</u>: An IT stakeholder who designs data structures and relationships to support documented business needs (e.g.: source system owners, Data Architects, DBAs (database administrators), and developers). Someone who manages schemas and/or models and owns technical metadata across all physical data assets.  They support the technical requirements of the requested data to prepare the data for extraction and provide data to IT Data Lake for the target environment.</li><li><u>Operational Steward</u>: Functional subject matter expert (SME) who works in the Operation Business Unit with the Business Steward and acts as their advocate, elicits, and documents data requirements, and understands details of the data such as the definitions of data elements. Partners with Technical Stewards and Data Governance SMEs heavily to establish a well-architected Data Solution on behalf of the business need.</li></ol>"
        },
        {
            section: "Ownership",
            question: "What does each role next to Data Science Product owner names represent? What questions can I ask each owner?",
            answer: "<p>Each product has owners that you can contact for more context about the data product. Below are role descriptions for each owner to help you understand which owner is best suited to answer your question about a data product.</p><p>Data science products have 7 owners:</p><ol><li><u>Product Owner</u>: See definition above under dashboards.</li><li><u>Data Science Lead</u>: Subject matter expert (SME) in Data Science Products. They work with business partners to translate challenging business problems to achievable analytical projects. They are knowledgeable in Statistical Analysis, Machine Learning, Deep Learning, Survival Modeling, Bayesian Statistics, and Optimization Methods.</li><li><u>BI Lead</u>: See definition above under dashboards.</li><li><u>Data Governance Lead</u>: A data governance subject matter expert (SME) who provides support to the Business/Operational/Technical Stewards and ED&A Project Team to document the business and technical Metadata. Coordinates with the requestor on Data Ingestion, Sharing and Access agreement across all sources and Data Domains.</li><li><u>Data Engineer Lead</u>: Data Engineer Leads are subject matter experts (SME) in Data Process and/or Data Science Products. They have a wide knowledge of data integration, data warehousing, modeling, database, and newly curated tables in Data Lake (SaraS).</li><li><u>IT Lead</u>: A technical expert in databases, tables and/or source systems. Supports the technical requirements of the requested data to prepare the data for extraction and provide data to IT Data Lake for the target environment.</li><li><u>Project Manager</u>: Project Managers are project leaders who help organize and facilitate the creation of a Data Process, Data Science products, and/or Power BI Reports.</li>"
        }
    ]

    const scrollToSection = (sectionRef) => {
        const { left, top } = sectionRef.current.getBoundingClientRect();
        window.scrollTo(left, top + window.scrollY - 150);
    }

    return (
        <section data-testid='faq-page' className='faq-page'>
            <div className='content--container faq--container'>
                <div className="header">
                    <BackButton id='faq-page' />
                    <div className='header--title'>
                        <h1>Frequently Asked Questions</h1>
                        <h2>Browse answers to frequently asked questions</h2>
                    </div>
                </div>
                <div className='bar-blue'></div>
                <div className='faq-content'>
                    <div className='faq-questions-container'>
                        {faqSections.map((faqSection, index) => {
                            return (
                                <div key={index} id={faqSection.sectionName} ref={faqSection.sectionRef}>
                                    <FAQSectionCard
                                        title={faqSection.sectionName}
                                        icon={faqSection.sectionIcon}
                                    >
                                        <Accordion id='faq-page-accordion' multiple={false} className='accordion-container' globalClicked={globalClicked} setGlobalClicked={setGlobalClicked} globalAccordion>
                                            {faqData.filter((faq) => faq.section === faqSection.sectionName).map((faq, idx) => {
                                                return (
                                                    <AccordionItem
                                                        id={`${faqSection.sectionName}-${idx}`}
                                                        key={`${faqSection.sectionName}-${idx}`}
                                                        name={faq.question}
                                                        value={faqData.indexOf(faq)}
                                                        type='accordion-lg'
                                                        className='accordion-item'
                                                    >
                                                        <div dangerouslySetInnerHTML={{ __html: faq.answer }}></div>
                                                    </AccordionItem>

                                                )
                                            })}
                                        </Accordion>
                                    </FAQSectionCard>
                                </div>
                            )
                        })}
                    </div>
                    <div className='faq-overview-container'>
                        <DetailCard
                            title='Browse Categories'
                        >
                            <span className='quick-access-links'>
                                {faqSections.map((section, idx) => {
                                    return (
                                        <Button
                                            id={`browse-category-${section.sectionName}`}
                                            data-testid={`browse-category-${section.sectionName}`}
                                            className='btn--plain-underline'
                                            key={idx}
                                            onClick={() => scrollToSection(section.sectionRef)}
                                        ><span>{section.sectionName}</span>
                                        </Button>
                                    );
                                })}
                            </span>
                        </DetailCard>
                        <div className='get-in-touch'>
                            <DetailCard
                                title='Still have questions or want to send us suggestions or feedback?'
                                paragraph='Let us know in the form below. We’d love to hear from you!'
                            >
                            <ButtonAddMarketPlace id="navbar" classBtn='btn--rounded-outline add-mktpl-btn' />
                            </DetailCard>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default FAQPage;