import PropTypes from 'prop-types';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Dropdown from '../Dropdown/Dropdown';
import './Form.scss';
import './FormComponent.scss';
import FormInput from './FormInput';
import FormTextArea from './FormTextArea';


const FormAddToMarketplace = ({ id, onFieldChange, passClearStateFunction, passValidateFormFunction }) => {
    const requestDateLabel = 'Date of Request'
    const dropdownLabel = 'Select one request type'
    const summaryLabel = 'Please elaborate on the option you chose above.'
    const requestedForLabel = 'Who are you requesting for?'
    

    const _initialState = {
        requestDate: { prompt: requestDateLabel, value: new Date() },
        dropdown: {prompt: dropdownLabel, value: ''},
        summary: { prompt: summaryLabel, value: '' },
        requestedFor: { prompt: requestedForLabel, value: '' }
    }
    const [summarizeError, setSummarizeError] = useState(false);
    const [dropdownError, setDropdownError] = useState(false);
    const [requestedForError, setRequestedForError] = useState(false);
    const [fieldLocalState, setFieldLocalState] = useState(_initialState);

    const clearState = () => {
        setSummarizeError(false);
        setDropdownError(false);
        setRequestedForError(false);
        setFieldLocalState(_initialState);
    }
    passClearStateFunction(clearState)

    const validateForm = () => {
        let isValid = true;
        if (fieldLocalState.summary.value === '') {
            setSummarizeError(true);
            isValid = false;
        }
        if (fieldLocalState.dropdown.value === '') {
            setDropdownError(true);
            isValid = false;
        }
        if (fieldLocalState.requestedFor.value === '') {
            setRequestedForError(true);
            isValid = false;
        }
        return isValid;
    }
    passValidateFormFunction(validateForm);

    const handleFieldChange = (key, value) => {
        if (key === 'summary') {
            value === '' ? setSummarizeError(true) : setSummarizeError(false);
        }
        if (key === 'dropdown') {
            value === '' ? setDropdownError(true) : setDropdownError(false);
        }
        if (key === 'requestedFor') {
            value === '' ? setRequestedForError(true) : setRequestedForError(false);
        }

        fieldLocalState[key].value = value;
        setFieldLocalState(fieldLocalState);
        onFieldChange(fieldLocalState);
    }

    return (
        <>
            <label htmlFor=''>{requestDateLabel}</label>
            <DatePicker
                disabled={true}
                selected={fieldLocalState.requestDate.value}
                onChange={(date) => handleFieldChange('requestDate', date)}
                value={fieldLocalState.requestDate.value}
                placeholderText='MM/DD/YYYY' />
            <label htmlFor=''>{'What type of request are you submitting?'}</label>
            
            <Dropdown
                id="dropdown"
                label={dropdownLabel}
                onChange={(option) => handleFieldChange('dropdown', option)}
                errorMessage='This Field is required'
                showError={dropdownError}
                options={['Dashboard Enhancement Request',
                    'Data Lake Ingestion Request', 
                    'New Project Idea',
                    'Data Question/Issue']} 
            />
            <FormTextArea
                id={`${id}-summarize`}
                type="textarea"
                errorMessage='This Field is required'
                required={true}
                rows="4"
                cols="50"
                name="summary"
                long={true}
                label={summaryLabel}
                value={fieldLocalState.summary.value}
                placeholder="Summarize data needed for ingestion to SaraS."
                handleChange={(e) => handleFieldChange('summary', e.target.value)}
                showError={summarizeError} />
            <FormInput
                id={`${id}-dataUsedFor`}
                type="text"
                errorMessage='This Field is required'
                showError={requestedForError}
                name="requestedFor"
                label={requestedForLabel}
                placeholder="List the full name(s)"
                value={fieldLocalState.requestedFor.value}
                handleChange={(e) => handleFieldChange('requestedFor', e.target.value)} />
            <div className='form--header--secondary'>
                Risk, Restrictions, and Conditions of Use
            </div>
            <label className='label-long question mb-6'>
                Data Privacy and Data Security will be engaged if there are PII and/or PCI data identified
            </label>
        </>
    )
}

FormAddToMarketplace.propTypes = {
    id: PropTypes.string,
    onFieldChange: PropTypes.func,
    passClearStateFunction: PropTypes.func,
    passValidateFormFunction: PropTypes.func
};

export default FormAddToMarketplace;