/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { sendEvent, trackSort } from '../../helpers/analyticsHandler';

import { Tabs, Tab, TabPanel } from '../../components/Tabs/Tabs';
import StatefulDatatable from '../datatable/StatefulDatatable';
import { MultiSelectFilter, ApplyStringFilter } from '../datatable/Filters';
import Button from '../../components/Button/Button';
import Dropdown from '../../components/Dropdown/Dropdown';
import { ArrowRight, Diamond, DiamondsFour } from 'phosphor-react';

const tagMap = {
    'NRG Enterprise': 'purple',
    'Home': 'blue',
    'Services': 'green',
    'Business': 'yellow'
};

const DataDefinition = ({ id, data, productType }) => {
    const [resultsCount, setResultsCount] = useState(0);
    const navigate = useNavigate();
    const defaultSort = [
        {
            id: 'name',
            desc: false
        }
    ];
    const [activeTab, setActiveTab] = useState(0);
    const [rowClicked, setRowClicked] = useState(-1);
    const handleChange = (e, value, id) => {
        if(activeTab===value && rowClicked===id){
            setActiveTab(0);
            setRowClicked(-1);
        }
        else {
            setActiveTab(value);
            setRowClicked(id);
        }
    };
    function checkRelationships(row) {
        if(row.original.relationships?.children?.filter(d => d.status==='Accepted').length>0 || 
            row.original.relationships?.synonyms?.filter(d => d.status==='Accepted').length>0 ||
            row.original.relationships?.parents?.filter(d => d.status==='Accepted').length>0 ){
                return true
            }
            return false;
    }
    const handleTermClick = (id, clickedId) => {
        navigate({
            pathname : `/term/${id}`,
            search: createSearchParams({
                clickedId: clickedId
            }).toString()
        });
    }
    const handleParentTermDropdown = (optionName, row) => {
        var selectedId = row.original.relationships?.parents?.filter(d => d.status==='Accepted').filter(d => d.name===optionName).map(d => d.id)
        handleTermClick(selectedId[0],row.original.id);
    }

    const getRelatedTermsTab = (row) => {
        if (row.original.type==='Acronym' && row.original.relationships?.children?.filter(d => d.status === 'Accepted').length > 0) {
            return <Tab id='glossary-page-tab-related-term' key={1} rowId={row.id} label="RELATED TERMS" value={1} caret={true}/>
        }
        return null;
    }
    const getSynonymTab = (row) => {
        if (row.original.type==='Business Term' && row.original.relationships?.synonyms?.filter(d => d.status === 'Accepted').length > 0) {
            return <Tab id='glossary-page-tab-synonym' key={2} rowId={row.id} label="SYNONYMS" value={2} caret={true}/>
        }
        return null;
    }

    const columns = productType === 'Dashboard' || productType === 'Data Science Product' ?
        [
            {
                Header: "Domain",
                accessor: "domain",
                filterLabel: "Domain",
                Filter: MultiSelectFilter,
                filter: ApplyStringFilter
            },
            {
                Header: "Segment",
                accessor: "businessSegment",
                filterLabel: "Segment",
                Filter: MultiSelectFilter,
                filter: ApplyStringFilter,
            },
            {
                Header: "Type",
                accessor: "type",
                filterLabel: "Type",
                Filter: MultiSelectFilter,
                filter: ApplyStringFilter
            },
            {
                Header: "Name",
                accessor: "name",
            },
            {
                Header: "Definition",
                accessor: "description",
                Cell: ({ row }) => (
                    <>
                        <span className='title'>{row.values.name}</span>
                        <div className='definition' dangerouslySetInnerHTML={{ __html: row.values.description }}></div>
                        <section className='tab'>
                        <div className={checkRelationships(row) ? 'tab--container' : 'tab--container-inactive'}>
                            <Tabs id='product-detail-page-tabs' selectedTab={activeTab} rowClicked={rowClicked} onChange={handleChange} className='all'>
                                {getRelatedTermsTab(row)}
                                {getSynonymTab(row)}
                            </Tabs>
                            {row.original.type==='Business Term' && 
                            <div className='grouped-terms' >
                                {row.original.relationships?.children?.filter(d => d.status==='Accepted').length>0 &&
                                <Button className='btn btn-child-terms' onClick={() => handleTermClick(row.original.id, row.original.id)}>
                                    <div className='diamond'><DiamondsFour /></div>
                                <div> <span className='term-title'>{`VIEW ${row.original.relationships?.children?.filter(d => d.status==='Accepted').length} CHILD TERMS`}</span></div>
                                <div className='circle'><ArrowRight size={16} weight="bold"/></div>
                                </Button>
                                }
                                {row.original.relationships?.parents?.filter(d => d.status==='Accepted').length===1 &&
                                <Button className='btn btn-child-terms' onClick={() => handleTermClick(row.original.relationships.parents.filter(d => d.status==='Accepted')[0].id,row.original.id)}>
                                    <div className='diamond'><Diamond/></div>
                                <div> <span className='term-title'>
                                    {`VIEW PARENT GROUP`}
                                    </span></div>
                                <div className='circle'><ArrowRight size={16} weight="bold"/></div>
                                </Button>
                                }
                                {row.original.relationships?.parents?.filter(d => d.status==='Accepted').length>1 &&
                                <Dropdown
                                id="dropdown"
                                label='Select parent group'
                                onChange={(option) => handleParentTermDropdown(option, row)}
                                options={row.original.relationships?.parents?.filter(d => d.status==='Accepted').map(d => d.name)}
                                sideIconDisplay={ArrowRight}
                                />
                                }
                            </div>   
                        }
                        </div>
                        <div className={activeTab===0 ? 'tab--panel-container inactive' : 'tab--panel-container'}>
                        {rowClicked===row.id && row.original.relationships?.children?.filter(d => d.status === 'Accepted').length > 0 &&
                        <TabPanel value={activeTab} selectedIndex={1}>
                            <div className='synonym-items'>                                            
                                <table >
                                    <tbody  className='glossary-page'>
                                        {row.original.relationships.children.filter(d => d.status === 'Accepted').map((relatedTerm, index) => {
                                            return(
                                                <tr key={index}  className={'synonym-row'}>
                                                <td key={index}  className='synonym-cell'>
                                                    <span className='title'>{relatedTerm.name}</span>
                                                    <div className='definition' dangerouslySetInnerHTML={{ __html: relatedTerm.description }}></div>
                                                    <div className='tags'>
                                                        <span className={`chip chip--${tagMap[relatedTerm.businessSegment] || 'grey'}`}>{relatedTerm.businessSegment}</span>
                                                        <span className='type'>{relatedTerm.type}</span>
                                                    </div>
                                                </td>
                                                </tr>    
                                            );
                                        })}    
                                    </tbody>
                                </table>        
                            </div>       
                            
                        </TabPanel>
                        }
                        {rowClicked===row.id && row.original.relationships?.synonyms?.filter(d => d.status === 'Accepted').length > 0 &&
                        <TabPanel value={activeTab} selectedIndex={2}> 
                            <div className='synonym-items'>                                            
                                <table >
                                    <tbody  className='glossary-page'>
                                        {row.original.relationships.synonyms.filter(d => d.status === 'Accepted').map((synonym, index) => {
                                            return(
                                                <tr key={index}  className={'test-synonym-row'}>
                                                <td key={index}  className='test-synonym-cell'>
                                                    <span className='title'>{synonym.name}</span>
                                                    <div className='definition' dangerouslySetInnerHTML={{ __html: synonym.description }}></div>
                                                    <div className='tags'>
                                                        <span className={`chip chip--${tagMap[synonym.businessSegment] || 'grey'}`}>{synonym.businessSegment}</span>
                                                        <span className='type'>{synonym.type}</span>
                                                    </div>
                                                </td>
                                                </tr>    
                                            );
                                        })}    
                                    </tbody>
                                </table>        
                            </div>       
                        </TabPanel>
                        }
                        </div>
                            </section>
                    </>
                )
            },
            {
                Header: "Tags",
                accessor: "tags",
                Cell: ({ row }) => (
                    <div className='tags'>
                        <span className={`chip chip--${tagMap[row.values.businessSegment] || 'grey'}`}>{row.values.businessSegment}</span>
                        <span className='type'>{row.values.type}</span>
                    </div>
                )
            },
        ] : [
            {
                Header: "Name",
                accessor: "name",
            },
            {
                Header: "Definition",
                accessor: "description",
                Cell: ({ row }) => (
                    <div className='definition' dangerouslySetInnerHTML={{ __html: row.values.description }}></div>
                )
            },
        ];

    const hiddenColumns = productType === 'Dashboard' || productType === 'Data Science Product' ? ["name", "domain", "type", "businessSegment"] : []

    function handleDataDefinitionsSortChange(option, setSortBy) {
        switch (option) {
            case 'Alphabetical A-Z':
                setSortBy([{ id: "name", desc: false }]);
                break;
            case 'Alphabetical Z-A':
                setSortBy([{ id: "name", desc: true }]);
                break;
            default:
                setSortBy([{ id: "name", desc: false }]);
        }

        trackSort(option);
        sendEvent('analyticsClickAction', 'product_sort', null, option, null);
    }

    return (
        <article id={id} data-testid={id} className='data-definition'>
            <div className='result'>
                <p>We found {resultsCount} results</p>
            </div>
            <StatefulDatatable
                pageName='product'
                id={`${id}-table`}
                columns={columns}
                data={data}
                hiddenColumns={hiddenColumns}
                sortOptions={['Alphabetical A-Z', 'Alphabetical Z-A']}
                onChangeSort={handleDataDefinitionsSortChange}
                defaultPageSize={20}
                defaultSortBy={defaultSort}
                setResultsCount={setResultsCount}
                hasGlobalFilter={true}
                displayCheck={false}
            />                      
        </article>
    );
};

DataDefinition.propTypes = {
    data: PropTypes.any,
    resultCount: PropTypes.any,
    productType: PropTypes.string,
    id: PropTypes.string
};
export default DataDefinition;