/* eslint-disable no-debugger */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Grid } from 'react-loader-spinner';

import Button from '../Button/Button';
import Dropdown from '../Dropdown/Dropdown';
import Checkbox from '../Form/FormCheckbox';
import FormInput from '../Form/FormInput';
import Modal from '../Modal/Modal';

import './PermissionRecord.scss';
export default function PermissionRecord({ id, isOpen, formType, record, header, handleClickDelete, handleClose, handleSave }) {
    const [isLoading, setIsLoading] = useState(false);
    const [formInputs, setFormInputs] = useState(null);
    const [inputErrors, setInputErrors] = useState({
        productType: false,
        productName: false,
        permittedUsers: false
    });

    useEffect(() => {
        setFormInputs({
            productType: record.type,
            productName: record.name,
            productGroups: record.groups,
            allowAll: record.allowAll
        })
    }, [record]);

    const validationInput = (key, value) => {
        switch (key) {
            case 'productName':
                return !value.length > 0;
            case 'productType':
                return !value.length > 0;
            default:
                return false;
        }
    }

    const validateForm = () => {
        let tempInputErrors = {};
        let validForm = true;

        if (formInputs?.productType?.length === 0) {
            tempInputErrors['productType'] = true;
            validForm = false;
        }
        if (formInputs?.productName?.length === 0) {
            tempInputErrors['productName'] = true;
            validForm = false;
        }
        if (!formInputs?.allowAll && formInputs?.productGroups.length === 0) {
            tempInputErrors['productUsers'] = true;
            validForm = false;
        }
        setInputErrors(tempInputErrors);
        return validForm;
    }

    const handleChangeInput = (name, value) => {
        setFormInputs({ ...formInputs, [name]: value });

        if (name === 'productName' || name === 'productType') {
            setInputErrors({ ...inputErrors, [name]: validationInput(name, value) });
        }
    }

    const handleInputPermittedChange = (event) => {
        const idx = event.target.id;
        let tempGroups = formInputs.productGroups
        tempGroups[idx] = event.target.value;
        setFormInputs({ ...formInputs, 'productGroups': tempGroups });
    };

    const handleClickSubmit = async () => {
        const valid = validateForm();

        if (valid) {
            setIsLoading(true);
            const payload = {
                name: formInputs.productName,
                type: formInputs.productType,
                allowAll: formInputs.allowAll,
                groups: formInputs.productGroups.filter(group => group.length > 0)
            }

            if (formType === 'EDIT') {
                payload['id'] = record.id
            }
            handleSave(payload)
            .then(response => {
                setIsLoading(false);
                handleCloseModal();
            });
        }
    }

    const addPermittedInput = (event) => {
        event.preventDefault();
        let tempGroups = formInputs.productGroups;
        tempGroups.push('');
        setFormInputs({ ...formInputs, 'productGroups': tempGroups });
    }

    const clearInputErrors = () => {
        setInputErrors({
            productType: false,
            productName: false,
            permittedUsers: false
        });
    }

    const handleCloseModal = () => {
        clearInputErrors();
        handleClose();
    } 

    return (
        <>
            {isOpen &&
                <Modal
                    id={id}
                    isOpen={isOpen}
                    handleClose={handleCloseModal}
                    size="md">
                    {header && <div className='modal-header'>{header}</div>}
                    <div className='modal-form-content'>
                        <div className='form'>
                            <form id={formType === 'EDIT' ? 'form-edit' : 'form-create'}>
                                <label>Product Type</label>
                                <Dropdown
                                    id="dropdown"
                                    label={record.type ? record.type : 'Product Type'}
                                    onChange={(event) => handleChangeInput('productType', event)}
                                    options={['Table', 'Data Science Product', 'Dashboard']}
                                    disabled={formType === 'EDIT'}
                                    showError={inputErrors.productType}
                                    errorMessage='This Field is required' />
                                <FormInput
                                    id={`${id}-product-name-record`}
                                    type="text"
                                    errorMessage='This Field is required'
                                    required
                                    name="productName"
                                    label="Product Name"
                                    placeholder="Product Name"
                                    value={formInputs?.productName}
                                    disabled={formType === 'EDIT'}
                                    showError={inputErrors.productName}
                                    handleChange={(e) => handleChangeInput(e.currentTarget.name, e.currentTarget.value)}
                                /> 
                                <Checkbox
                                    id={`${id}-all-nrg-users-record`}
                                    data-testid={`${id}-all-nrg-users-record`}
                                    value={formInputs?.allowAll ? formInputs.allowAll : false}
                                    isChecked={formInputs?.allowAll ? formInputs.allowAll : false}
                                    onChange={() => handleChangeInput('allowAll', !formInputs.allowAll)}
                                    label="Allow All NRG Users" 
                                    showError={inputErrors.productUsers}
                                    errorMessage="Either select this OR add a Permitted Group"/>
                                <div className='permitted-groups'>
                                    <label>Permitted Group Names</label>
                                    {
                                        formInputs?.productGroups.map((item, idx) => {
                                            return (
                                                <input
                                                    key={idx}
                                                    id={idx}
                                                    data-testid={idx}
                                                    value={item}
                                                    type='text'
                                                    onChange={(e) => handleInputPermittedChange(e)}
                                                    disabled={formInputs?.allowAll}
                                                />
                                            )
                                        })
                                    }
                                    <Button
                                        id="button-add-permitted-group"
                                        data-testid="button-add-permitted-group"
                                        className='btn btn--rounded btn--blue-light'
                                        onClick={(e) => addPermittedInput(e)}
                                        disabled={formInputs?.allowAll}
                                    >
                                        + 
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button
                            id={`${id}-save-btn`}
                            className='btn--rounded btn--blue-light'
                            onClick={handleClickSubmit}
                            disabled={isLoading}
                        >
                            Save
                        </Button>
                        {isLoading && <span><Grid color="#999999" height={20} width={20} ariaLabel='loading' wrapperClass="modal-spinner" /></span>}
                    </div>
                </Modal>
            }
        </>
    );
}


PermissionRecord.propTypes = {
    id: PropTypes.string,
    isOpen: PropTypes.bool,
    formType: PropTypes.string,
    record: PropTypes.any,
    header: PropTypes.string,
    handleClickDelete: PropTypes.func,
    handleClose: PropTypes.func,
    handleSave: PropTypes.func
};