/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo, useContext } from 'react';
import { Grid } from 'react-loader-spinner';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { GetGlossary, GetAllAssetPermissions } from '../../services/dmpApi';
import { sendEvent, trackSort } from '../../helpers/analyticsHandler';
import { Context } from '../../contexts/context';
import { Actions } from "../../contexts/action";

import StatefulDatatable from '../../components/datatable/StatefulDatatable';
import BackButton from '../../components/Button/BackButton/BackButton';
import ProductTile from '../../components/ProductTile/ProductTile';
import DetailDrawer from '../../components/detailDrawer/DetailDrawer';
import { Tabs, Tab, TabPanel } from '../../components/Tabs/Tabs';
import { MultiSelectFilter, ArrayMultiSelectFilter, ApplyArrayFilter, ApplyStringFilter } from '../../components/datatable/Filters';

import './GlossaryPage.scss';
import Button from '../../components/Button/Button';
import Dropdown from '../../components/Dropdown/Dropdown';
import { ArrowRight, Diamond, DiamondsFour } from 'phosphor-react';

const tagMap = {
    'NRG Enterprise': 'purple',
    'Home': 'blue',
    'Services': 'green',
    'Business': 'yellow'
};

export default function GlossaryPage() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [results, setResults] = useState([]);
    const [resultsCount, setResultsCount] = useState(0);
    const [rowClicked, setRowClicked] = useState(-1);
    const {
        state: {
            dashboardPermissions
        },
        dispatch
    } = useContext(Context);
    const [showModal, setShowModal] = useState(false);
    const [modalId, setModalId] = useState('');
    const [isDashboardsLoaded, setIsDashboardsLoaded] = useState(dashboardPermissions ? true : false);
    const [activeTab, setActiveTab] = useState(0);
    const [fetchedResultsCount, setFetchedResultsCount] = useState(0);
    const [totalResultsCount, setTotalResultsCount] = useState(0);
    const handleChange = (e, value, id) => {
        if(activeTab===value && rowClicked===id){
            setActiveTab(0);
            setRowClicked(-1);
        }
        else {
            setActiveTab(value);
            setRowClicked(id);
        }
    };

    function checkDashboards(row){
        return row.original.relationships?.dashboards?.filter(d => dashboardPermissions.find(dashboard => d.id === dashboard.id))
                    .filter(d => d.status === 'Accepted' || d.status === 'reviewed').length > 0
    }

    function checkTables(row) {
        return row.original.relationships?.tables?.filter(d => dashboardPermissions.find(table => d.id === table.id))
                .filter(d => d.status === 'Accepted' || d.status==='Reviewed').length > 0
    }

    function checkDataProcesses(row) {
        return row.original.relationships?.dataProcesses?.filter(d => dashboardPermissions.find(dataProcess => d.id === dataProcess.id))
                .filter(d => d.status === 'Accepted').length > 0
    }
    
    const getWhereIsTheTermUsedTab = (row) => {
        if (checkDashboards(row) || checkTables(row) || checkDataProcesses(row)) {
            return <Tab id='glossary-page-tab-where-term' key={1} rowId={row.id} label="WHERE IS THE TERM USED?" value={1} caret={true}/>
        }
        return null;
    }
    const getRelatedTermsTab = (row) => {
        if (row.original.type==='Acronym' && row.original.relationships?.children?.filter(d => d.status === 'Accepted').length > 0) {
            return <Tab id='glossary-page-tab-related-term' key={2} rowId={row.id} label="RELATED TERMS" value={2} caret={true}/>
        }
        return null;
    }
    const getSynonymTab = (row) => {
        if (row.original.type==='Business Term' && row.original.relationships?.synonyms?.filter(d => d.status === 'Accepted').length > 0) {
            return <Tab id='glossary-page-tab-synonym' key={3} rowId={row.id} label="SYNONYMS" value={3} caret={true}/>
        }
        return null;
    }

    const handleTermClick = (id, clickedId) => {
        navigate({
            pathname : `/term/${id}`,
            search: createSearchParams({
                clickedId: clickedId
            }).toString()
        });
    }

    function checkRelationships(row) {
        if(row.original.relationships?.children?.filter(d => d.status==='Accepted').length>0 || 
            row.original.relationships?.synonyms?.filter(d => d.status==='Accepted').length>0 ||
            row.original.relationships?.parents?.filter(d => d.status==='Accepted').length>0 || 
            checkDashboards(row) || checkTables(row) || checkDataProcesses(row)){
                return true
            }
            return false;
    }

    const handleParentTermDropdown = (optionName, row) => {
        var selectedId = row.original.relationships?.parents?.filter(d => d.status==='Accepted').filter(d => d.name===optionName).map(d => d.id)
        handleTermClick(selectedId[0],row.original.id);
    }
    async function LoadData(limit, offset) {
        GetGlossary(limit, offset)
            .then(response => {
                setFetchedResultsCount(limit+offset)
                setTotalResultsCount(response.data.total)
                const newArray = [...results, ...response.data.results]
                setResults(newArray);
                setIsLoading(false);
            }).catch(error => {
                setIsLoading(false);
                console.log(error);
            });
    }

    useEffect(() => {
        LoadData(100,0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(fetchedResultsCount<totalResultsCount){
            LoadData(100,fetchedResultsCount)
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [results]);

    useEffect(() => {
        const LoadDashboards = async () => {
            const response = await GetAllAssetPermissions()
                .catch((err) => console.log(err));

            if (response) {
                const data = response.data;

                let tempDashboardPermissions = data.results.map(item => {
                    return {
                        id: item.id,
                        access: item.access,
                        appUrl: item.appUrl,
                        hasAccess: item.hasAccess
                    }
                });

                dispatch({
                    type: Actions.ADD_DASHBOARDS,
                    payload: tempDashboardPermissions
                });
               setIsDashboardsLoaded(true);
            }
        }

        if (!dashboardPermissions) {
            LoadDashboards();
        }
    }, [dispatch, dashboardPermissions]);

    const data = useMemo(() => [...results], [results]);

    function productTileFunc(product, index ) {
        return(<>
        <ProductTile
            key={index}
            id={`glossary-page-dashboard-${product.id}`}
            name={product.type === 'Database View' || product.type === 'Table' ? product.name.split(">").pop() : product.name}
            type={product.type}
            access={dashboardPermissions.filter((d) => d.id === product.id)[0]?.hasAccess}
            launchButtonAccess = {false}
            handleClick={(e) => handleProductClick(e, {
                id: product.id,
                name: product.name,
                type: product.type
            })}
            favoriteItem={product}
        />
        </>);
    }

    const columns = useMemo(
        () => [
            {
                Header: "Domain",
                accessor: "domain",
                filterLabel: "Domain",
                Filter: ArrayMultiSelectFilter,
                filter: ApplyArrayFilter
            },
            {
                Header: "Name",
                accessor: "name",
            },
            {
                Header: "Description",
                accessor: "description"
            },
            {
                Header: "Business Segment",
                accessor: "businessSegment",
                filterLabel: "Segment",
                Filter: ArrayMultiSelectFilter,
                filter: ApplyArrayFilter
            },
            {
                Header: "Type",
                accessor: "type",
                filterLabel: "Type",
                Filter: MultiSelectFilter,
                filter: ApplyStringFilter
            },
            {
                Header: "Definition",
                accessor: "definition",
                Cell: ({ row }) => (
                    <>
                        <span className='title'>{row.values.name}</span>
                        <div className='definition' dangerouslySetInnerHTML={{ __html: row.values.description }}></div>
                        <div className='tags'>
                            <span className={`chip chip--${tagMap[row.values.businessSegment] || 'grey'}`}>{row.values.businessSegment}</span>
                            <span className='type'>{row.values.type}</span>
                        </div>
                        <section className='tab'>
                        <div className={checkRelationships(row) ? 'tab--container' : 'tab--container-inactive'}>
                            <Tabs id='product-detail-page-tabs' selectedTab={activeTab} rowClicked={rowClicked} onChange={handleChange} className='all'>
                                {getWhereIsTheTermUsedTab(row)}
                                {getRelatedTermsTab(row)}
                                {getSynonymTab(row)}
                            </Tabs>
                            <Tabs id='product-detail-page-tabs' selectedTab={activeTab} onChange={handleChange} className='single'>
                                {getWhereIsTheTermUsedTab(row)}
                            </Tabs>
                            {row.original.type==='Business Term' && 
                            <div className='grouped-terms' >
                                {row.original.relationships?.children?.filter(d => d.status==='Accepted').length>0 &&
                                <Button className='btn btn-child-terms' onClick={() => handleTermClick(row.original.id, row.original.id)}>
                                    <div className='diamond'><DiamondsFour /></div>
                                <div> <span className='term-title'>{`VIEW ${row.original.relationships?.children?.filter(d => d.status==='Accepted').length} CHILD TERMS`}</span></div>
                                <div className='circle'><ArrowRight size={16} weight="bold"/></div>
                                </Button>
                                }
                                {row.original.relationships?.parents?.filter(d => d.status==='Accepted').length===1 &&
                                <Button className='btn btn-child-terms' onClick={() => handleTermClick(row.original.relationships.parents.filter(d => d.status==='Accepted')[0].id,row.original.id)}>
                                    <div className='diamond'><Diamond/></div>
                                <div> <span className='term-title'>
                                    {`VIEW PARENT GROUP`}
                                    </span></div>
                                <div className='circle'><ArrowRight size={16} weight="bold"/></div>
                                </Button>
                                }
                                {row.original.relationships?.parents?.filter(d => d.status==='Accepted').length>1 &&
                                <Dropdown
                                id="dropdown"
                                label='Select parent group'
                                onChange={(option) => handleParentTermDropdown(option, row)}
                                options={row.original.relationships?.parents?.filter(d => d.status==='Accepted').map(d => d.name)}
                                sideIconDisplay={ArrowRight}
                                />
                                }
                            </div>   
                        }
                        </div>
                        <div className={activeTab===0 ? 'tab--panel-container inactive' : 'tab--panel-container'}>
                        {rowClicked===row.id && 
                        <TabPanel value={activeTab} selectedIndex={1}>
                        {!isDashboardsLoaded ?
                                        <div className="loading-spinner" id="grid-loading">
                                            <Grid color="#999999" height={100} width={110} ariaLabel='loading' />
                                            <span>Loading....</span>
                                        </div>
                                        : <div className='dashboard-items'>
                                           {row.original.relationships.dashboards?.filter(d => d.status === 'Accepted' || d.status === 'Reviewed').map((dashboard, index) => {
                                                if (dashboardPermissions.filter((d) => d.id === dashboard.id)[0]) {
                                                    return (  <>{productTileFunc(dashboard, index)}</>)
                                                }
                                                else {
                                                    return (<></>)
                                                }
                                            })}
                                            {row.original.relationships.tables?.filter(d => d.status === 'Accepted' || d.status === 'Reviewed').map((table, index) => {
                                                  if (dashboardPermissions.filter((d) => d.id === table.id)[0]) {
                                                    return (  <>{productTileFunc(table, index)}</>)
                                                }
                                                else {
                                                    return (<></>)
                                                }
                                                })}
                                            {row.original.relationships.dataProcesses?.filter(d => d.status === 'Accepted').map((dataProcess, index) => {
                                                  if (dashboardPermissions.filter((d) => d.id === dataProcess.id)[0]) {
                                                    return (  <>{productTileFunc(dataProcess, index)}</>)
                                                }
                                                else {
                                                    return (<></>)
                                                }
                                                })}
                                        </div>
                                    }
                            </TabPanel>
                        }
                        {rowClicked===row.id && row.original.relationships?.children?.filter(d => d.status==='Accepted').length > 0 &&
                        <TabPanel value={activeTab} selectedIndex={2}>
                        {isLoading ?
                            <div className="loading-spinner" id="grid-loading">
                                <Grid color="#999999" height={100} width={110} ariaLabel='loading' />
                                <span>Loading....</span>
                            </div>
                            : 
                            <div className='synonym-items'>                                            
                                <table >
                                    <tbody  className='glossary-page'>
                                        {row.original.relationships.children.filter(d => d.status === 'Accepted').sort((a,b) => {return a.name<b.name?-1:1}).map((relatedTerm, index) => {
                                            return(
                                                <tr key={index}  className={'synonym-row'}>
                                                <td key={index}  className='synonym-cell'>
                                                    <span className='title'>{relatedTerm.name}</span>
                                                    <div className='definition' dangerouslySetInnerHTML={{ __html: relatedTerm.description }}></div>
                                                    <div className='tags'>
                                                        <span className={`chip chip--${tagMap[relatedTerm.businessSegment] || 'grey'}`}>{relatedTerm.businessSegment}</span>
                                                        <span className='type'>{relatedTerm.type}</span>
                                                    </div>
                                                </td>
                                                </tr>    
                                            );
                                        })}    
                                    </tbody>
                                </table>        
                            </div>       
                            }
                        </TabPanel>
                        }
                        {rowClicked===row.id && row.original.relationships?.synonyms?.filter(d => d.status==='Accepted').length > 0 &&
                        <TabPanel value={activeTab} selectedIndex={3}>
                        {isLoading ?
                            <div className="loading-spinner" id="grid-loading">
                                <Grid color="#999999" height={100} width={110} ariaLabel='loading' />
                                <span>Loading....</span>
                            </div>
                            : 
                            <div className='synonym-items'>                                            
                                <table >
                                    <tbody  className='glossary-page'>
                                        {row.original.relationships.synonyms.filter(d => d.status === 'Accepted').sort((a,b) => {return a.name<b.name?-1:1}).map((synonym, index) => {
                                            return(
                                                <tr key={index}  className={'test-synonym-row'}>
                                                <td key={index}  className='test-synonym-cell'>
                                                    <span className='title'>{synonym.name}</span>
                                                    <div className='definition' dangerouslySetInnerHTML={{ __html: synonym.description }}></div>
                                                    <div className='tags'>
                                                        <span className={`chip chip--${tagMap[synonym.businessSegment] || 'grey'}`}>{synonym.businessSegment}</span>
                                                        <span className='type'>{synonym.type}</span>
                                                    </div>
                                                </td>
                                                </tr>    
                                            );
                                        })}    
                                    </tbody>
                                </table>        
                            </div>       
                            }
                        </TabPanel>
                        }
                        </div>
                            </section>                   
                    </>
                )
            }
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dashboardPermissions, activeTab, rowClicked]
    );

    const hiddenColumns = useMemo(() => ["name", "description", "businessSegment", "type", "domain"], []);

    function handleGlossarySortChange(option, setSortBy) {
        switch (option) {
            case 'Alphabetical A-Z':
                setSortBy([{ id: "name", desc: false }]);
                break;
            case 'Alphabetical Z-A':
                setSortBy([{ id: "name", desc: true }]);
                break;
            default:
                setSortBy([{ id: "name", desc: false }]);
        }

        trackSort(option);
        sendEvent('analyticsClickAction', 'glossary_sort', null, option, null);
    }

    const handleProductClick = (e, product) => {
        let hasAccess = false;
        let filteredDashboard = dashboardPermissions.filter(item => item.id === product.id);

        if (filteredDashboard.length > 0) {
            hasAccess = filteredDashboard[0].hasAccess;
        }

        if (hasAccess === true || product.type === 'Data Science Product') {
            navigate(`/product/${product.id}`, {
                state: {
                    id: product.id,
                    name: product.name,
                    type: product.type
                }
            });
        } else {
            e.stopPropagation();
            setModalId(product.id);
            setShowModal(!showModal);
        }

        sendEvent('analyticsClickAction', 'browse_favoritesOpen', null, null, product);
    }

    return (
        <section data-testid='glossary-page'>
            <div className='content--container glossary--container'>
                <div className="header">
                    <BackButton id='glossary-page' />
                    <div className='header--title'>
                        <h1>Glossary</h1>
                        <h2>Find definitions within the marketplace</h2>
                    </div>
                </div>
                <div className='bar-blue'></div>
                <div className='data-container'>
                    {isLoading ?
                        <div className="loading-spinner" id="grid-loading">
                            <Grid color="#999999" height={100} width={110} ariaLabel='loading' />
                            <span>Loading....</span>
                        </div> :
                        <>
                            <div className='result'>
                                <p>We found {resultsCount} results</p>
                            </div>                           
                            <StatefulDatatable
                                pageName='glossary'
                                id='glossary-page-datatable'
                                columns={columns}
                                data={data}
                                hiddenColumns={hiddenColumns}
                                sortOptions={['Alphabetical A-Z', 'Alphabetical Z-A']}
                                onChangeSort={handleGlossarySortChange}
                                defaultPageSize={20}
                                setResultsCount={setResultsCount}
                                hasGlobalFilter={true}
                                fetchedResultsCount={fetchedResultsCount}
                                totalResultsCount={totalResultsCount}
                                displayCheck={true}
                            />
                            <DetailDrawer
                                id={`glossary-page-details-${modalId}`}
                                itemId={modalId}
                                modalActive={showModal}
                                setModalState={() => setShowModal(!showModal)}
                                data-testid='glossary-page-details'
                            />
                        </>
                    }
                </div>
            </div>
        </section>
    );
}