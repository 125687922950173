import React, { useState } from 'react'
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';

import FormTextArea from './FormTextArea';
import FormInput from './FormInput';

import './FormComponent.scss';
import './Form.scss';
import 'react-datepicker/dist/react-datepicker.css';


const FormRequestDataExtract = ({ id, onFieldChange, passClearStateFunction, passValidateFormFunction, assetName }) => {
	const productNameLabel = 'Data Product Name'
	const requestDateLabel = ''
	const summaryLabel = 'Summarize your data needs and how the data will be used.'
	const receiptMethodLabel = 'How would you like to receive the data requested?'

	const _initialState = {
		productName: { prompt: productNameLabel, value: assetName },
		requestDate: { prompt: requestDateLabel, value: new Date() },
		summary: { prompt: summaryLabel, value: '' },
		receiptMethod: { prompt: receiptMethodLabel, value: '' }
	}
	const [summarizeError, setSummarizeError] = useState(false);
	const [fieldLocalState, setFieldLocalState] = useState(_initialState);

	const clearState = () => {
		setSummarizeError(false);
		setFieldLocalState(_initialState);
	}
	passClearStateFunction(clearState)

	const validateForm = () => {
		let valid = true;
		if (fieldLocalState.summary.value === '') {
			setSummarizeError(true);
			valid = false;
		}
        return valid;
	}

	passValidateFormFunction(validateForm);

	const handleFieldChange = (key, value) => {
		if (key === 'summary') {
			value === '' ? setSummarizeError(true) : setSummarizeError(false);
		}

		fieldLocalState[key].value = value;
		setFieldLocalState(fieldLocalState);
		onFieldChange(fieldLocalState);
	}

	return (
		<>
			<FormInput
                id={`${id}-${assetName}`}
                type="text"
                name="productName"
                label="Data Product Name"
                value={fieldLocalState.productName.value}
                disabled={true} />
			<DatePicker
				disabled={true}
				selected={fieldLocalState.requestDate.value}
				onChange={(date) => handleFieldChange('requestDate', date)}
				value={fieldLocalState.requestDate.value}
				placeholderText='MM/DD/YYYY' />
			<FormTextArea
				id={`${id}-summarize`}
				type="textarea"
				errorMessage='This Field is required'
				required={true}
				rows="4"
				cols="50"
				name="summary"
				label="Summarize your data needs and how the data will be used."
				placeholder="Describe your data needs in business terms. Please list any tables, reports, and/or data science products that will require access."
				value={fieldLocalState.summary.value}
				handleChange={(e) => handleFieldChange('summary', e.target.value)}
				showError={summarizeError} />
			<FormTextArea
				id={`${id}-dataSetModel`}
				type="textarea"
				errorMessage='This Field should be filled'
				rows="4"
				cols="50"
				name="receiptMethod"
				label="How would you like to receive the data requested?"
				placeholder="i.e., xls, csv, or secured location"
				value={fieldLocalState.receiptMethod.value}
				handleChange={(e) => handleFieldChange('receiptMethod', e.target.value)} />
			<div className='form--header--secondary'>
                B. Risk, Restrictions and Conditions of Use
            </div>
            <label className='label-long question mb-6'>
				Data Privacy and Data Security will be engaged if there are PII and/or PCI data identified
            </label>
		</>
	)
}

FormRequestDataExtract.propTypes = {
	id: PropTypes.string,
	onFieldChange: PropTypes.func,
	passClearStateFunction: PropTypes.func,
	passValidateFormFunction: PropTypes.func,
	assetName: PropTypes.string
}

export default FormRequestDataExtract;