import React, { useContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as Browse } from '../../assets/icons/browse.svg';
import { ReactComponent as BrowseFill } from '../../assets/icons/browse-fill.svg';
import { ReactComponent as Favorite } from '../../assets/icons/favorite.svg';
import { ReactComponent as FavoriteFill } from '../../assets/icons/favorite-fill.svg';
import { ReactComponent as Glossary } from '../../assets/icons/glossary.svg';
import { ReactComponent as GlossaryFill } from '../../assets/icons/glossary-fill.svg';
import { ReactComponent as Help } from '../../assets/icons/help.svg';
import { ReactComponent as HelpFill } from '../../assets/icons/help-fill.svg';
import { ReactComponent as AdminFill } from '../../assets/icons/admin-fill.svg';
import { ReactComponent as Admin } from '../../assets/icons/admin.svg';
import { ReactComponent as RecommendedFill } from '../../assets/icons/recommended-fill.svg';
import { ReactComponent as Recommended } from '../../assets/icons/recommended.svg';

import { Context } from '../../contexts/context';

import TutorialModal from '../Modal/TutorialModal'
import RecommendationsPopup from '../RecommendationsPopup/RecommendationsPopup';


export default function NavbarMenu({ classNavbar, id, onItemClick }) {
    const [showHelpMenu,setShowHelpMenu] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const navigate = useNavigate();
    const onMouseEnter = () => {
        setShowHelpMenu(true);
    }
    const onMouseLeave = () => {
        setShowHelpMenu(false);
    }
    const onClickHelp = () => {
        onItemClick('Help');
        setShowHelpMenu(!showHelpMenu);
    }

    const sideHelpRef = useRef(null);
    useEffect(() => {
        /**
         * Close if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (sideHelpRef.current && !sideHelpRef.current.contains(event.target)) {
                setShowHelpMenu(false);
            }
        }
        if (showHelpMenu) {
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [sideHelpRef, showHelpMenu]);
    const {
        state: {
            isAdmin
        },
    } = useContext(Context);
    const location = useLocation();

    const toggleTutorialModal = () => {
        setShowModal(!showModal);
    }

    const getActive = (page) => {
        if (location.pathname.includes(page)) {
            return true;
        }

        if (page === 'browse' && location.pathname === '/') {
            return true;
        }
        return false;
    }

    useEffect(() => {
        setShowPopup((JSON.parse(sessionStorage.getItem('view-popup'))));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionStorage.getItem('view-popup')]);

    return (
        <nav role="navigation" className={classNavbar} data-testid={`${id}-navbar-menu`}>
            <Link 
                data-testid={`${id}-browse`} 
                onClick={() => onItemClick} 
                to={'/'} 
                className={`${getActive('browse') && 'active'}`} >
                <Browse className={`align nav-block ${getActive('browse') && 'block'}`} />
                <BrowseFill className={`align nav-fill ${getActive('browse') && 'active'}`} />
                Browse
            </Link>
            <Link 
                data-testid={`${id}-glossary`}
                onClick={() => onItemClick} 
                to={'/glossary'}
                state={{ defaultPageIndex: 0 }}
                className={`${getActive('glossary') && 'active'}`} >
                <Glossary className={`align nav-block ${getActive('glossary') && 'block'}`} />
                <GlossaryFill className={`align nav-fill ${getActive('glossary') && 'active'}`} />
                Glossary
            </Link>
            <Link data-testid={`${id}-favorite`} onClick={() => onItemClick}
                to={'/favorite'}
                state={{ defaultPageIndex: 0 }} 
                className={`${getActive('favorite') && 'active'}`} >
                <Favorite className={`align nav-block ${getActive('favorite') && 'block'}`} />
                {this}
                <FavoriteFill className={`align nav-fill ${getActive('favorite') && 'active'}`} />
                Favorites
            </Link>
            <div className='recommendation-container'>
                <Link data-testid={`${id}-recommended`} onClick={() => onItemClick}
                    to={'/recommendations'}
                    state={{ defaultPageIndex: 0 }}
                    className={`${getActive('recommendations') && 'active'}`}
                > 
                    <Recommended className={`align nav-block ${getActive('recommendations') && 'block'}`} />
                    {this}
                    <RecommendedFill className={`align nav-fill ${getActive('recommendations') && 'active'}`} />
                     Recommended
                </Link>
                <div className={`recommendation-popup ${getActive('browse')}`} >
                <RecommendationsPopup 
                    id='browse-page-pop-up'
                    isOpen={showPopup && location.pathname==='/'}
                    handleClose={() => {setShowPopup(false); sessionStorage.setItem('view-popup', false);}}
                    handleAction={() => {navigate('/recommendations'); sessionStorage.setItem('view-popup', false);}}
                />
            </div></div>
            <div className={`sidebar-menu-help-container`}  onMouseEnter={() => onMouseEnter()} onMouseLeave={() => onMouseLeave()}>
                <Link id={`${id}-help`} data-testid={`${id}-help`} onClick={() => onClickHelp('Help')}   
                    className={`${getActive('faq') && 'active'}`} ref={sideHelpRef}>
                    <Help className={`align nav-block ${getActive('faq') && 'block'}`} />
                    {this}
                    <HelpFill className={`align nav-fill ${getActive('faq') && 'active'}`} />
                    Help
                </Link>
                <nav role="navigation" 
                    className={showHelpMenu? `${classNavbar}-help` : `${classNavbar}-help-no`} data-testid={`${id}-navbar-menu`}
                     ref={sideHelpRef} >
                        <Link onClick={() => onItemClick('FAQ')}
                            to={'/faq'} >
                            FAQ
                        </Link>                     
                    <Link onClick={() => {onItemClick('Search Tutorial'); toggleTutorialModal();}} >
                        Search Tutorial
                    </Link>
                    </nav>
                </div>
            <Link id={`${id}-faq`} data-testid={`${id}-faq`} onClick={() => onItemClick('FAQ')} 
                className={`${getActive('faq') && 'active'}`} to={'/faq'}>
                    <Help className={`align nav-block ${getActive('faq') && 'block'}`} />
                {this}
                <HelpFill className={`align nav-fill ${getActive('faq') && 'active'}`} />
                FAQ
            </Link>
            {isAdmin && 
                <Link onClick={() => onItemClick('Admin')}
                    to={'/admin-console'}
                    state={{ defaultPageIndex: 0 }} 
                    className={`${getActive('admin') && 'active'}`} >
                    <Admin className={`align nav-block ${getActive('admin') && 'block'}`} />
                    {this}
                    <AdminFill className={`align nav-fill ${getActive('admin') && 'active'}`} />
                    Admin
                </Link>
            }

            
            <TutorialModal 
                id = "tutorial-modal"
                isOpen = {showModal} 
                handleClose= {toggleTutorialModal}
            />   
        </nav>
    )
}

NavbarMenu.propTypes = {
    id: PropTypes.string,
    classNavbar: PropTypes.string,
    onItemClick: PropTypes.func
}