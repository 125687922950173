/*jshint esversion: 6 */
import React from 'react';
import PropTypes from 'prop-types';

import dashboard from '../../assets/icons/dashboard.svg';
import table from '../../assets/icons/table.svg';
import dataScience from '../../assets/icons/data-science.svg';
import searchTerm from '../../assets/icons/search-term.svg';
import feature from '../../assets/icons/feature.svg';

const iconMap = {
    'Dashboard': dashboard,
    'Table': table,
    'Data Science Product': dataScience,
    'Search Term': searchTerm,
    'Feature': feature
}

export default function ProductIcon({ id, productType }) {
    return <img data-testid={id} src={iconMap[productType]} alt={productType + " Icon"} className='typeIcon' />;
}

ProductIcon.propTypes = {
    id: PropTypes.string,
    productType: PropTypes.string
}