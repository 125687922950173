/* eslint-disable react/prop-types */
import React, { useState, useMemo, useContext, useEffect } from 'react';
import './FavoritePage.scss';
import { Grid } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Context } from '../../contexts/context';
import { TrackAssetUsage } from '../../services/dmpApi';
import { sendEvent } from '../../helpers/analyticsHandler';

import Button from '../../components/Button/Button';
import DetailDrawer from '../../components/detailDrawer/DetailDrawer';
import FormContainer from '../../components/Form/FormContainer';
import ConfirmationModal from '../../components/Modal/ConfirmationModal';
import Favorite from '../../components/FavoriteElements/FavoriteIcon/Favorite';
import FavoritesUnavailable from '../../components/FavoriteElements/FavoritesUnavailable/FavoritesUnavailable';
import ProductIcon from '../../components/ProductElements/ProductIcon';
import BackButton from '../../components/Button/BackButton/BackButton';
import ProductAccessOrigin from '../../components/ProductElements/ProductAccessOrigin';
import Toggle from '../../components/Toggle/Toggle';

import shareIcon from '../../assets/icons/share.svg';
import arrow from '../../assets/icons/arrow-up.svg';
import FavoritesGroupedView from '../../components/FavoriteGroupedView/FavoritesGroupedView';
import FavoritesTableView from '../../components/FavoritesTableView/FavoritesTableView';
import ErrorModal from '../../components/Modal/ErrorModal';

export default function FavoritePage(props) {
    const {
        state: {
            isLoaded,
            favorites,
        }
    } = useContext(Context);
    const navigate = useNavigate();
    const [isFavoritesLoading, setIsFavoritesLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [modalId, setModalId] = useState('');
    const [results, setResults] = useState([]);
    const [resultsCount, setResultsCount] = useState(0);
    const [showRequestAccessModal, setShowRequestAccessModal] = useState(false);
    const [showRequestAccessConfirmModal, setShowRequestAccessConfirmModal] = useState(false);
    const [showRequestExtractModal, setShowRequestExtractModal] = useState(false);
    const [showRequestExtractConfirmModal, setShowRequestExtractConfirmModal] = useState(false);
    const [showModalError, setShowModalError] = useState(false);
    const [clickedItem, setClickedItem] = useState(null);
    const [toggleOption, setToggleOption] = useState(
        window.localStorage.getItem('favoritePageView') 
        ? window.localStorage.getItem('favoritePageView') 
        : 'Domain Groups'
    );

    const columns = useMemo(
        () => [
            {
                Header: "Name",
                accessor: "name",
                Cell: ({ row }) => (
                    <>
                        <ProductIcon productType={row.original.type} />
                        <div className={row.original.type === 'Dashboard' ? 'dashboard' : 'table'}>{row.values.name}</div>
                        <img src={arrow} alt='name-arrow' className='arrow-separator' />
                    </>
                )
            },
            {
                Header: "",
                accessor: "hasAccess",
                filterLabel: "Access",
            },
            {
                Header: "",
                accessor: "favoritedAt",
                filterLabel: "Favorited At",
            },
            {
                Header: "",
                accessor: "lastAccessed",
                filterLabel: "Last Used",
            },
            {
                Header: "Data Origin",
                accessor: "reportType",
                Cell: ({ row }) => (
                    <ProductAccessOrigin id='favorite-page-access-origin' access={row.values.hasAccess} reportType={row.values.reportType} />
                )
            },
            {
                id: "Edit",
                Header: "",
                accessor: "hasAccess",
                Cell: ({ row }) => (
                    <>
                        <div className='favorite'>
                            <Favorite favoriteItem={row.original} />
                        </div>
                        {row.values.hasAccess === true ?
                            <div className='access-buttons'>
                                <Button id={`${row.original.id}-view-product`} className={'btn--blue-light ' + (row.original.type === 'Table' ? 'access left' : 'access-single')} onClick={(e) => handleShowProduct(e, row.original)} disabled={row.original.type === 'Data Science Product' && row.original.presentation === ''}>
                                    {row.original.type === 'Data Science Product' ? 'Launch Presentation' : 'Launch Product'} <img src={shareIcon} alt='Share Icon' className='typeIcon share-icon' />
                                </Button>
                                {row.original.type === 'Table' &&
                                    <Button id={`${row.original.id}-request-extract`} className='btn--blue-light access right' onClick={(e) => { handleRequestExtractClicked(e, row.original) }}>
                                        Request Extract
                                    </Button>
                                }
                            </div>
                            : <Button id={`${row.original.id}-request-access`} className='btn btn--blue-light no-access' onClick={(e) => { handleRequestAccessClicked(e, row.original) }}>Request Access</Button>}
                    </>

                ),
            },
        ],

        // eslint-disable-next-line react-hooks/exhaustive-deps
        []);

    function handleRowClick(row) {
        if (row.hasAccess === true || row.type ==='Data Science Product') {
            navigate(`/product/${row.id}`, {
                state: {
                    id: row.id,
                    name: row.name,
                    type: row.type
                }
            });
        } else {
            setModalId(row.id);
            setShowModal(!showModal);
        }

        sendEvent('analyticsClickAction', 'favorite_openReport', null, null, row);
    }

    const handleClickRequestAccessConfirmation = () => {
        setShowRequestAccessConfirmModal(!showRequestAccessConfirmModal);
        setShowRequestAccessModal(!showRequestAccessModal);
    }

    const handleClickRequestExtractConfirmation = () => {
        setShowRequestExtractConfirmModal(!showRequestExtractConfirmModal);
        setShowRequestExtractModal(!showRequestExtractModal);
    }

    const handleModalErrorRequestAccess = () => {
        setShowModalError(!showModalError)
        setShowRequestAccessModal(!showRequestAccessModal)
    }

    const handleModalErrorRequestExtract = () => {
        setShowModalError(!showModalError)
        setShowRequestExtractModal(!showRequestExtractModal);
    }

    const handleRequestExtractClicked = (e, row) => {
        e.stopPropagation();
        setClickedItem(row);
        setShowRequestExtractModal(true);
        setModalId(row.id);
        sendEvent('analyticsClickAction', 'favorite_request', null, 'Request Extract', row);
    }

    const handleRequestAccessClicked = (e, row) => {
        e.stopPropagation();
        setClickedItem(row);
        setShowRequestAccessModal(true);
        setModalId(row.id);
        sendEvent('analyticsClickAction', 'favorite_request', null, 'Request Access', row);
    }

    const handleShowProduct = (e, row) => {
        e.stopPropagation();
        openProductHandler(row);
    }

    function openProductHandler(row) {
        TrackAssetUsage(row.id)
            .catch(error => {
                console.log(error);
            });

        sendEvent('analyticsClickAction', 'favorite_launch', null, null, row);

        const newWindow = window.open(getProductUrl(row), '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    }

    function getProductUrl(data) {
        if (data.type === 'Data Science Product') {
            return data.presentation;
        } else if (data.access === 'app' && data.appUrl) {
            return data.appUrl;
        }

        return data.url;
    }
    
    function handleToggleChange(activeToggle) {
        if (activeToggle !== toggleOption) {
            window.localStorage.setItem('favoritePageView', activeToggle);
            setToggleOption(activeToggle);
        }
    }

    useEffect(() => {
        setResults(favorites);
        if (isLoaded) {
            setIsFavoritesLoading(false);
        }
    }, [isLoaded, favorites]);

    return (
        <section data-testid='favorite-page'>
            <div className='content--container favorite-page'>
                <div className="header">
                    <BackButton id='favorite-page' />
                    <div className='header--title'>
                        <span className='favorites-page-title'>
                            <h1>My Favorites</h1>
                            <h2>Browse all your favorited items here</h2>
                        </span>
                        <span className='view-toggle'>
                            <Toggle
                                optionLeft={'Ungrouped'}
                                optionRight={'Domain Groups'}
                                activeOption={toggleOption}
                                onChange={handleToggleChange}
                            />
                        </span>
                    </div>
                </div>
                <div className='bar-blue'></div>
                <div className='data-container'>
                    {!isFavoritesLoading && results.length > 0 && toggleOption === 'Ungrouped' ? <p className='results'>You have {resultsCount} favorited items</p> : null}
                    {isFavoritesLoading ?
                        <div className="loading-spinner" id="grid-loading">
                            <Grid color="#999999" height={100} width={110} ariaLabel='loading' />
                            <span>Loading....</span>
                        </div> :
                        results.length > 0 ?
                            <>
                                <DetailDrawer
                                    id='favorite-page-details'
                                    itemId={modalId}
                                    modalActive={showModal}
                                    setModalState={(state) => setShowModal(state)}
                                    data-testid={props.id}
                                />
                                {toggleOption === 'Ungrouped' ? 
                                    <FavoritesTableView 
                                        id='favorites-table-view'
                                        results={results}
                                        columns={columns}
                                        setResultsCount={setResultsCount}
                                        handleRowClick={(row) => handleRowClick(row.original)}
                                    />
                                    :
                                    <FavoritesGroupedView 
                                        id='favorites-grouped-view'
                                        favoritesResults={results}
                                        handleItemClick={handleRowClick}
                                        handleLaunchProduct={handleShowProduct}
                                        handleRequestExtract={handleRequestExtractClicked}
                                        handleRequestAccess={handleRequestAccessClicked}
                                    />
                                }
                                <FormContainer
                                    id={'favorite-page-form-modal'}
                                    isOpen={showRequestAccessModal}
                                    handleClose={() => setShowRequestAccessModal(false)}
                                    header={'Request Access'}
                                    typeForm="DAA"
                                    handleClickConfirmation={handleClickRequestAccessConfirmation}
                                    handleError={handleModalErrorRequestAccess}
                                    asset={{
                                        id: clickedItem?.id,
                                        name: clickedItem?.name,
                                        type: clickedItem?.type,
                                        primaryOwners: clickedItem?.primaryOwner,
                                        secondaryOwners: clickedItem?.secondaryOwner
                                    }}
                                />
                                <ConfirmationModal
                                    isOpen={showRequestAccessConfirmModal}
                                    id={'favorite-page-confirmation-modal'}
                                    handleClose={() => setShowRequestAccessConfirmModal(false)}
                                    header='Thank you for submitting your request for access.'
                                    message='You can expect confirmation email from Data Governance in 1 to 2 days and Data Governance will notify you of approval.'
                                ></ConfirmationModal>
                                <FormContainer
                                    id={'favorite-page-form-modal'}
                                    isOpen={showRequestExtractModal}
                                    handleClose={() => setShowRequestExtractModal(false)}
                                    header={'Request Data Extract'}
                                    typeForm={'DSA'}
                                    handleClickConfirmation={handleClickRequestExtractConfirmation}
                                    handleError={handleModalErrorRequestExtract}
                                    asset={{
                                        id: clickedItem?.id,
                                        name: clickedItem?.name,
                                        type: clickedItem?.type,
                                        primaryOwners: clickedItem?.primaryOwner,
                                        secondaryOwners: clickedItem?.secondaryOwner
                                    }}
                                />
                                <ConfirmationModal
                                    id={'favorite-page-confirm-modal'}
                                    isOpen={showRequestExtractConfirmModal}
                                    handleClose={() => setShowRequestExtractConfirmModal(false)}
                                    header="Thank you for submitting your request for data extract."
                                    message='You can expect a confirmation email from Data Governance in 1 to 2 days and Data Governance will notify you of approval.'
                                >
                                </ConfirmationModal>
                                <ErrorModal
                                    isOpen={showModalError}
                                    id={'favorite-page-error-modal'}
                                    handleClose={() => setShowModalError(false)}
                                    message={'Your request has failed. Please submit your request again.'}
                                ></ErrorModal>
                            </>
                            :
                            <div className='favorites-unavailable'>
                                <FavoritesUnavailable />
                            </div>
                    }
                </div>
            </div>
        </section>
    );
}

FavoritePage.propTypes = {
    id: PropTypes.string
}