import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import ReactTooltip from "react-tooltip";
import documentIcon from '../../assets/icons/document-icon.svg';
import ErrorIcon from '../../assets/icons/error-icon.svg';
import inProgressIcon from '../../assets/icons/in-progress.svg';
import questionLabel from '../../assets/icons/question-label.svg';
import successCheck from '../../assets/icons/success-check.svg';
import { GetDownloadPresignedUrl, GetPreSignedUrl, UploadDataScienceS3File } from '../../services/dmpApi';
import Button from '../Button/Button';
import Dropdown from '../Dropdown/Dropdown';
import FileUpload from '../FileUpload/FileUpload';
import CheckDownloadDSExecutionModal from '../Modal/CheckDownloadDSExecutionModal';
import ErrorModal from '../Modal/ErrorModal';
import FormInput from './FormInput';

import 'react-datepicker/dist/react-datepicker.css';
import './Form.scss';
import './FormComponent.scss';
import './FormDataScienceModelExecution.scss';

const FormDataScienceModelExecution = ({ id, model_id, assetName, onFieldChange, modelExecutionParameters, 
    DSModelStatus, handleSubmit, lastDSModalErrorMessage, lastDSModalOutputDate, lastDSModelRunId, previousRunDownloadStatus}) => { 

    const _initialState = {}
    modelExecutionParameters.forEach(element => {_initialState[element.name] = "" });
    const [fieldLocalState, setFieldLocalState] = useState(_initialState);
    const [invalidNumberCheck, setInvalidNumberCheck] = useState(true);
    const [statusName, setStatusName] = useState("");
    const [statusHeader, setStatusHeader] = useState("");
    const [statusInfo, setStatusInfo] = useState("");
    const [statusBar, setStatusBar] = useState("inActive");
    const [disableFormFields, setDisableFormFields] = useState(false);
    const [fileUploadProgress, setFileUploadProgress] = useState(0);
    const [statusIcon, setStatusIcon] = useState("");
    const [showModalError, setShowModalError] = useState(false);
    const [checkDownloadStatus, setCheckDownloadStatus] = useState(false);

    function handleUpload (chosenFile, name) {
        GetPreSignedUrl(id, assetName, "."+chosenFile.name.split(".").at(-1), name)
            .then(response => {
                setFileUploadProgress(0);
                uploadS3File(response.data.uploadUrl, chosenFile, name)
            }).catch(error => {
                console.log(error);
            });
    }

    function uploadS3File (presignedUrlDetails, chosenFile, name) {
        UploadDataScienceS3File(presignedUrlDetails, chosenFile, (event) => {
            setFileUploadProgress(Math.round((100 * event.loaded) / event.total))
        })
        .then(response => {
            handleFileUploadFieldChange(name,presignedUrlDetails.url, presignedUrlDetails.fields.key)
        }).catch(error => {
            console.log(error)
        })   
    }

    const handleFileUploadFieldChange = (name, url, key) => {
        var temp = url.split('/').at(-2)
        url = "s3://"+temp.split('.').at(0)+"/"+key
        handleFieldChange(name, url, "string")
    }

    const handleDSModelRun = () => {
        if(DSModelStatus==="Execution Successful" && previousRunDownloadStatus!=="True"){
            setCheckDownloadStatus(true)
        }else{
            handleSubmit(model_id, id)
        }
    }

    const convertEnumValue = (obj, ui_value) => {
        for(var i of obj){
            if(i.ui_value===ui_value){
                return i.value
            }
        }
        return "";
    }

    const convertToDate =(dateString) => {
        if(dateString===""){
            return ""
        }
        var parts = dateString.split("-")
        return new Date(parts[0], parts[1] - 1, parts[2])
    }

    const convertToDateString=(dateString) => {
        if(dateString===""){
            return ""
        }
        return `${dateString.split("Z")[0]}`
    }
    const handleDateFieldChange = (key, date) => {
        var year = date.toLocaleString("default", { year: "numeric" });
        var month = date.toLocaleString("default", { month: "2-digit" });
        var day = date.toLocaleString("default", { day: "2-digit" });
        return handleFieldChange(key, year + "-" + month + "-" + day)
    }
    const handleFieldChange = (key, value, type) => {
        if(type==="number"){
            value = value ? parseFloat(value) : ""
        }
        fieldLocalState[key]=value;
        setFieldLocalState(fieldLocalState);
        onFieldChange(fieldLocalState)
    }

    function validateParams (){
        for(var param in fieldLocalState){
            if(fieldLocalState[param]===""){
                return false
            }
        }
        if(!invalidNumberCheck){
            return true
        }
        return false;
    }

    function checkValidNumber(param){
        if(fieldLocalState[param.name]!==""){
            if(param.minimum!=null && param.maximum!=null && fieldLocalState[param.name]>=param.minimum && fieldLocalState[param.name]<=param.maximum ){
                if(invalidNumberCheck){
                    setInvalidNumberCheck(false)
                }
                return true;   
            }
        }
        if(!invalidNumberCheck){
            setInvalidNumberCheck(true)
        }
        return false;
    }

    function modelStatus(){
        switch(DSModelStatus){
            case 'Validating':
                setStatusBar("Validation")
                setStatusName("Validating Input")
                setStatusHeader("Your input is being validated.")
                setStatusInfo("If the file Structure is validated, your run will proceed. If any errors are present, you will be able to re-run your model.")
                setDisableFormFields(true)
                setStatusIcon(documentIcon)
                break;
            case 'Validation Successful':
                setStatusBar("Validation")
                setStatusName("In Progress")
                setStatusHeader("Your Input was validated and the model run is in progress.")
                setStatusInfo("Once your model run is complete, you will be notified via an email and your output will be ready to download in this panel in marketplace.")
                setDisableFormFields(true)
                setStatusIcon(inProgressIcon)
                break;
            case 'Validation Failed' :
                setStatusBar("Failed")
                setStatusName("Error Found")
                setStatusHeader("File Structure is invalid.")
                setStatusInfo("please re-upload the file with the correct column names and run your model.")
                setDisableFormFields(false)
                setStatusIcon(ErrorIcon)
                break;
            case 'Execution Successful' :
                setStatusBar("Success")
                setStatusName("Complete: Download Ready")
                setStatusHeader("Model Run Completed [Success].")
                setStatusInfo("Download the output below.")
                setDisableFormFields(false)
                setStatusIcon(successCheck)
                break;
            case 'Execution Failed':
                setStatusBar("Failed")
                setStatusName("Complete: Model Run Failed")
                setStatusHeader("Model run completed [Failed].")
                setStatusInfo(lastDSModalErrorMessage)
                setDisableFormFields(false)
                setStatusIcon(ErrorIcon)
                setShowModalError(true)
                break;
            case 'Execution Error':
                setStatusBar("Failed")
                setStatusName("Error Found")
                setStatusHeader("Model Run Request Failed.")
                setStatusInfo(lastDSModalErrorMessage+" Please retry later.")
                setDisableFormFields(true)
                setStatusIcon(ErrorIcon)
                break;
            default:
                setStatusBar("inActive")
                setStatusName("")
                setStatusHeader("")
                setStatusInfo("")
                setStatusIcon("")
                setDisableFormFields(false)
                break;
        }
    }

    const fetchDownloadPresignedUrl = async(runId) => {
         await GetDownloadPresignedUrl(runId)
         .then(response => { 
            const link = document.createElement('a')
            link.href = response.data.downloadUrl
            link.setAttribute('download','OutputFileTest.xlsx')
            document.body.appendChild(link)
            link.click()
            alert(`Downloading ${assetName} Execution Output File !!`)
             
         })
         .catch((err) => {
             console.log(err)
             alert("Couldn't download your file right at the moment. Please try again !")

         })
     }

    useEffect(() => {
        modelStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[DSModelStatus])

    function parameterFields (inputParameter) {          
        switch (inputParameter.data_format) {
            case 's3uri': 
                return (
                    <>
                    <label className='parameter-label'>
                        <span>{inputParameter.short_desc}</span>
                        <img src={questionLabel} alt='help question' data-for={`${inputParameter.name}-tip`} data-tip={inputParameter.long_desc} />
                    </label>
                    <ReactTooltip id={`${inputParameter.name}-tip`} className='custom-color-no-arrow' delayHide={1000}
                    textColor='#FFF' backgroundColor='#231F20' effect='solid' />
                    <FileUpload
                        handleUpload={handleUpload}
                        uriPattern = {inputParameter.pattern}
                        parameterName = {inputParameter.name}
                        label={inputParameter.short_desc}
                        required = {inputParameter.required}
                        disabled={disableFormFields}
                        progress = {fileUploadProgress}
                        />
                    <label className='data-error-required'>{fieldLocalState[inputParameter.name]==="" && !disableFormFields ? "This field is required" : ""}</label></>   
                )
            case 'date':
                return (
                    <>
                    <label className='parameter-label'>
                        <span>{inputParameter.short_desc}</span>
                        <img src={questionLabel} alt='help question' data-for={`${inputParameter.name}-tip`} data-tip={inputParameter.long_desc} />
                    </label>
                    <ReactTooltip id={`${inputParameter.name}-tip`} className='custom-color-no-arrow' delayHide={1000}
                    textColor='#FFF' backgroundColor='#231F20' effect='solid' />
                    <DatePicker
                        disabled={disableFormFields}
                        selected={convertToDate(fieldLocalState[inputParameter.name])}
                        onChange={(date) => handleDateFieldChange(inputParameter.name, date, inputParameter.data_type)}
                        value={convertToDate(fieldLocalState[inputParameter.name])}
                        label={inputParameter.short_desc}
                        placeholderText='MM/DD/YYYY' 
                        required={inputParameter.required}
                        minDate={new Date()}
                       />
                        <label className='data-error-required'>{fieldLocalState[inputParameter.name]==="" && !disableFormFields ? "This field is required" : ""}</label></>
                )
            default:
                return(
                    <>
                    {inputParameter.enum !==null ? 
                    <>
                    <label className='parameter-label'><span>{inputParameter.short_desc}</span>
                    <img src={questionLabel} alt='help question' data-for={`${inputParameter.name}-tip`} data-tip={inputParameter.long_desc} /></label>
                    <ReactTooltip id={`${inputParameter.name}-tip`} className='custom-color-no-arrow' delayHide={1000}
                textColor='#FFF' backgroundColor='#231F20' effect='solid' />
                    <Dropdown
                        id="dropdown"
                        label={inputParameter.short_desc}
                        onChange={(option) => handleFieldChange(inputParameter.name,convertEnumValue( inputParameter.enum, option), inputParameter.data_type)}
                        options={inputParameter.enum.map((obj,index) => { return obj.ui_value})} 
                        placeHolder="Choose Value"
                        showError={fieldLocalState[inputParameter.name]==="" && !disableFormFields}
                        errorMessage={"Please choose a value"}
                        disabled={disableFormFields}
                    /></>:
                    <FormInput
                        id={`${id}-${inputParameter.name}`}
                        type={inputParameter.data_type==='number' ? "number" : "text"}
                        name={inputParameter.name}
                        label={inputParameter.short_desc}
                        placeholder={'Enter Value'}
                        required = {inputParameter.required}
                        value={fieldLocalState[inputParameter.name].toString() }
                        showtooltip = {true}
                        pattern= {inputParameter.data_type==='number' ? "^[0-9]+([.][0-9]+)?" : null}
                        tooltip={inputParameter.long_desc}
                        disabled={ disableFormFields}
                        showError={!disableFormFields ? (inputParameter.data_type!=='number' ? fieldLocalState[inputParameter.name]==="" : !checkValidNumber(inputParameter)): false}
                        errorMessage={inputParameter.data_type==='number' ? `Please enter a number between ${inputParameter.minimum} and ${inputParameter.maximum}` : "This value is required"}
                        handleChange={(e) => handleFieldChange(inputParameter.name, e.target.value, inputParameter.data_type)} />
                    }</>)
                }
    }
    
    return (
        <>
        <div className='form-ds-model'>
            <div className='top-bar'></div>
            {DSModelStatus!=="" && <div className={`status-bar ${statusBar}`}>
                <div className='status-header'>
                    <label className='status-name'>
                    <img src={statusIcon} alt='validating input status' />
                    <span>{statusName}</span>
                    </label>
                </div>
                <div className='status-content'>
                    <label className='status-update'><span>Status: {statusHeader}</span></label>
                    <label className='status-info'>
                        <p>{statusInfo}</p>
                        {(DSModelStatus==="Execution Successful" || DSModelStatus==="Execution Failed") &&
                        <span>Last run date: {convertToDateString(lastDSModalOutputDate)}</span>}
                    </label>
                </div>
                {DSModelStatus === "Execution Successful" && <div className='form-download-btn'>
                <Button
                    id={`${id}-download-btn`}
                    className='btn--rounded btn--blue-light btn--submit'
                    onClick={() => fetchDownloadPresignedUrl(lastDSModelRunId)}
                    disabled={lastDSModelRunId===""}
                    >
                    Download Output
                </Button>
                </div>}
                
            </div>}
            <form>
                <div className='form-header'>
                <p className='model-header'>Run the Model...</p>
                <p className='model-info'>Please submit all parameters before running the model.</p>
                </div>
                <div className={`form-content ${!disableFormFields}`}>
                {modelExecutionParameters.map((obj,index) => {
                       return ( <>{parameterFields(obj,index)}</>)
                    })}
                </div>
                <div className='form-run-btn'>
                <Button
                    id={`${id}-submit-btn`}
                    className='btn--rounded btn--blue-light btn--submit'
                    onClick={() => validateParams() ? handleDSModelRun() : ""}
                    disabled={!validateParams() || disableFormFields}
                    >
                    Run
                </Button>
                </div>
            </form>      
        </div>
        <ErrorModal
                id={`${id}-error-modal`}
                isOpen={showModalError}
                handleClose={() => setShowModalError(false)}
                header={"Model Run Failed"}
                message={'The DataBricks Model has failed. Please try re-running the model again, or contact the Data Science Lead in the Product owners section.'}
            >
        </ErrorModal>
        <CheckDownloadDSExecutionModal
                id={`${id}-confirm-modal`}
                isOpen={checkDownloadStatus}
                handleClose={() => setCheckDownloadStatus(false)}
                handleDownload = {() => {fetchDownloadPresignedUrl(lastDSModelRunId);setCheckDownloadStatus(false)}}
                handleContinue = {() => {setCheckDownloadStatus(false);handleSubmit(model_id, id)}}
                header="Download Output"
                message="You haven't downloaded your output yet. Running a new model will replace your current output and you will be not be able to download it again."
            >
        </CheckDownloadDSExecutionModal>
        </> 
    )
};

FormDataScienceModelExecution.propTypes = {
    id: PropTypes.string,
    model_id: PropTypes.string,
    assetName: PropTypes.string,
    handleSubmit: PropTypes.func,
    modelExecutionParameters: PropTypes.array,
    onFieldChange: PropTypes.func,
    DSModelStatus: PropTypes.string,
    lastDSModalErrorMessage: PropTypes.string,
    lastDSModalOutputDate: PropTypes.string,
    lastDSModelRunId: PropTypes.string,
    previousRunDownloadStatus: PropTypes.string
};

export default FormDataScienceModelExecution;