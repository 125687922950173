import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Grid } from 'react-loader-spinner';

import { sendEvent } from '../../helpers/analyticsHandler';
import { SubmitForm } from '../../services/dmpApi';

import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import FormAddToMarketplace from './FormAddToMarketplace';
import FormRequestAccess from './FormRequestAccess';
import FormRequestDataExtract from './FormRequestDataExtract';

import './Form.scss';

const FormContainer = ({ id, isOpen, handleClose, header, typeForm, handleClickConfirmation, handleError, asset }) => {
    const requestTypeLabel = ''
    const productIdLabel = ''
    const productNameLabel = ''
    const productTypeLabel = ''
    const productOwnerLabel = ''
    const biLeadLabel = ''

    const [isLoading, setIsLoading] = useState(false);
    const [formFields, setFormFields] = useState({});
    const formHeaders = {
        DIA: 'Summarize your data needs and how the data will be used',
        DAA: 'Who will need access?',
        DSA: 'What will the data be used for?'
    }

    const requestTypeMapping = {
        'DIA': 'Data Ingestion Request',
        'DSA': 'Data Sharing Request',
        'DAA': 'Data Access Request',
        'NIR': 'New Idea Request',
        'OR': 'Other Request',
        'NPI' : 'New Project Idea',
        'DER' : 'Dashboard Enhancement Request',
        'DQI': 'Data Question/Issue'
    }

    let clearChildState = null;
    const assignClearChildState = (clearState) => {
        clearChildState = clearState;
    }

    let validateFormSubmission = null;
    const assignValidateFormSubmission = (validate) => {
        validateFormSubmission = validate;
    }

    const handleCloseModal = () => {
        handleClose();
        clearChildState();
        setFormFields({});
    }

    const handleFormFieldChange = (childState) => {
        setFormFields({...formFields, ...childState});
    }

    const handleSubmit = async () => {
        const valid = validateFormSubmission();

        if (valid) {
            setIsLoading(true);
            
            let assetId = asset?.id ? asset?.id : '';
            let assetName = asset?.name ? asset?.name : '';
            let assetType = asset?.type ? asset?.type : '';
            let assetProductOwner = asset?.primaryOwners?.find(owner => owner.roleName === 'Product Owner');
            let assetProductOwnerEmail = assetProductOwner ? assetProductOwner.email : '';
            let assetBILead = asset?.secondaryOwners?.find(owner => owner.roleName === 'BI Lead');
            let assetBILeadEmail = assetBILead ? assetBILead.email : '';

            //type form might change based on drop down selection for a Data Ingestion Request (DIA)
            if (typeForm === "DIA" && (formFields.dropdown.value !=='Data Lake Ingestion Request')) {
                if(formFields.dropdown.value === 'New Project Idea') typeForm='NPI'
                if(formFields.dropdown.value === 'Dashboard Enhancement Request') typeForm='DER'
                if(formFields.dropdown.value === 'Data Question/Issue') typeForm='DQI'
            }

            if(typeForm==='DER') assetType='Dashboard'
            if(typeForm==='DIA' || typeForm==='DQI') assetType='Ingestion'
            
            const payload = {
                requestType: {prompt: requestTypeLabel, value: requestTypeMapping[typeForm]},
                productId: { prompt: productIdLabel, value: assetId },
                productName: { prompt: productNameLabel, value: assetName },
                productType: { prompt: productTypeLabel, value: assetType },
                productOwner: { prompt: productOwnerLabel, value: assetProductOwnerEmail },
                biLead: { prompt: biLeadLabel, value: assetBILeadEmail },
                ...formFields,
            }
            var check = false;
            if(typeForm==='DIA' || typeForm==='DQI'){
                const newWindow = window.open('https://nrg.collibra.com/dashboard?d=d3784b9f-d808-430c-a652-60145f537c49', '_blank');

                if(newWindow){ 
                    check=true;  
                }
                else{
                    
                    setIsLoading(false)
                    handleError(typeForm==='DIA' || typeForm==='DQI');
                }
            }
            
            if(check || (typeForm!=='DIA' && typeForm!=='DQI')){
                SubmitForm(payload)
                .then(res => {
                    setIsLoading(false);
                    handleClickConfirmation(typeForm==='DIA' || typeForm==='DQI');
                    sendEvent('analyticsClickAction', 'modalSubmit', typeForm, null, asset ? asset : null);
                    
                }).catch(err => {
                    setIsLoading(false);
                    console.error(err);
                    handleError(typeForm==='DIA'|| typeForm==='DQI');
                });
            
            setFormFields({});
            }
            
        }
    }

    return (
        <>
            {isOpen &&
                <Modal id={id} isOpen={isOpen} handleClose={handleCloseModal} size="md">
                    {header && <div className='modal-header'>{header}</div>}
                    <div className='modal-form-content'>
                        <div className='form'>
                            <div className='form--header'>
                                {formHeaders[typeForm]}
                            </div>
                            <form>        
                                {(typeForm === 'DIA' && <FormAddToMarketplace 
                                    id={id} 
                                    onFieldChange={handleFormFieldChange}
                                    passClearStateFunction={assignClearChildState} 
                                    passValidateFormFunction={assignValidateFormSubmission} />) ||
                                (typeForm === 'DAA' && <FormRequestAccess 
                                    id={id} 
                                    onFieldChange={handleFormFieldChange} 
                                    passClearStateFunction={assignClearChildState} 
                                    passValidateFormFunction={assignValidateFormSubmission}
                                    assetName={asset?.name} />) ||
                                (typeForm === 'DSA' && <FormRequestDataExtract 
                                    id={id} 
                                    onFieldChange={handleFormFieldChange}
                                    passClearStateFunction={assignClearChildState} 
                                    passValidateFormFunction={assignValidateFormSubmission}
                                    assetName={asset?.name} />)}
                            </form>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button
                            id={`${id}-submit-btn`}
                            className='btn--rounded btn--blue-light btn--submit'
                            onClick={() => handleSubmit()}
                            disabled={isLoading}>
                            Submit
                        </Button>
                        {isLoading && <span><Grid color="#999999" height={20} width={20} ariaLabel='loading' wrapperClass="modal-spinner" /></span>}
                    </div>
                </Modal>
            }
        </>
    )
}

FormContainer.propTypes = {
    id: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    header: PropTypes.string,
    typeForm: PropTypes.string,
    handleClickConfirmation: PropTypes.func,
    handleError: PropTypes.func,
    asset: PropTypes.any
}

export default FormContainer;