/* eslint-disable no-debugger */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Grid } from 'react-loader-spinner';
import ReactTooltip from "react-tooltip";

import arrow from '../../assets/icons/arrow-up.svg';
import { ReactComponent as HasAccessFlat } from '../../assets/icons/hasAccess-flat.svg';
import { ReactComponent as HasNoAccessFlat } from '../../assets/icons/hasNoAccess-flat.svg';

import { Actions } from '../../contexts/action';
import { Context } from '../../contexts/context';
import { sendEvent, trackSort } from '../../helpers/analyticsHandler';
import { DeletePermission, GetPermission, SubmitPermission } from '../../services/dmpApi';
import Button from '../Button/Button';
import ConfirmRecordDeleteModal from '../Modal/ConfirmRecordDeleteModal';
import PermissionRecord from '../PermissionRecord/PermissionRecord';
import ProductIcon from '../ProductElements/ProductIcon';
import StatefulDatatable from '../datatable/StatefulDatatable';
import './PermissionSettings.scss';

export default function PermissionSettingsPage(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [loadError, setLoadError] = useState('');
    const [results, setResults] = useState([]);
    const [resultsCount, setResultsCount] = useState(0);
    const [showUpdateRecordModal, setShowUpdateRecordModal] = useState(false);
    const [showConfirmDeleteRecordModal, setShowConfirmDeleteRecordModal] = useState(false);
    const [formType, setFormType] = useState('');
    const [clickedItem, setClickedItem] = useState({
        id: '',
        type: '',
        name: '',
        groups: [],
        allowAll: false
    });

    const {
        dispatch
    } = useContext(Context);

    useEffect(() => {
        LoadData();
    }, []);

    const handleDelete = () => {
        DeletePermission(clickedItem.id)
            .then(res => {
                const toastProperties = {
                    id: clickedItem.id,
                    type: 'success',
                    title: 'Record Deleted',
                    message: `Record has been successfully deleted`
                }
                dispatch({ type: Actions.ADD_NOTIFICATION, payload: toastProperties });
                LoadData();
            }).catch(err => {
                const toastProperties = {
                    id: clickedItem.id,
                    type: 'error',
                    title: 'Error',
                    message: `Record was not successfully deleted`
                }
                dispatch({ type: Actions.ADD_NOTIFICATION, payload: toastProperties });
                console.error(err);
            });

            clearClickedItem();
    };

    async function UpdateRecord(record) {
        return SubmitPermission(record)
            .then(response => {
                const toastProperties = {
                    id: clickedItem.id,
                    type: 'success',
                    title: 'Record Added',
                    message: `Record has been successfully added`
                }
                dispatch({ type: Actions.ADD_NOTIFICATION, payload: toastProperties });
                LoadData();
            }).catch(error => {
                const toastProperties = {
                    id: clickedItem.id,
                    type: 'error',
                    title: 'Error',
                    message: `Record was not successfully added`
                }
                dispatch({ type: Actions.ADD_NOTIFICATION, payload: toastProperties });
                console.error(error);
            });
    }

    function LoadData() {
        setIsLoading(true);
        GetPermission()
            .then(response => {
                setResults(response.data);
                if (loadError.length > 0) setLoadError('');
                setIsLoading(false);
            }).catch(error => {
                setLoadError('Error loading Search results. ' + error);
                setIsLoading(false);
                console.log(error);
            });
    }

    function handleRowClick(row) {
        setFormType('EDIT');
        setClickedItem(row.original)
        setShowUpdateRecordModal(true);
    }

    function clearClickedItem() {
        setClickedItem({
            id: '',
            type: '',
            name: '',
            groups: [],
            allowAll: false
        });
    }

    function handleClickAddRecord() {
        setFormType('ADD');
        clearClickedItem();
        setShowUpdateRecordModal(true);
    }

    const handleClickDeleteModalConfirmation = () => {
        setShowConfirmDeleteRecordModal(!showConfirmDeleteRecordModal);
    }

    const handleClickDeleteRecord = () => {
        handleDelete();
        setShowConfirmDeleteRecordModal(false);
        setShowUpdateRecordModal(false);
    }

    const handleClickDeleteModalCancel = () => {
        setShowConfirmDeleteRecordModal(false);
        setShowUpdateRecordModal(false);
    }

    const handleClickCloseModal = () => {
        setShowUpdateRecordModal(false);
        clearClickedItem();
    }

    function handleSortChange(option, setSortBy, sortBy) {
        switch (option) {
            case 'Alphabetical A-Z':
                setSortBy([{ id: "name", desc: false }]);
                break;
            case 'Alphabetical Z-A':
                setSortBy([{ id: "name", desc: true }]);
                break;
            default:
                setSortBy([{ id: "id", desc: false }]);
        }

        trackSort(option);
        sendEvent('analyticsClickAction', 'admin_permissions_sort', null, option, null);
    }

    const columns = useMemo(
        () => [
            {
                Header: "Name",
                accessor: "nameIcon",
                Cell: ({ row }) => (
                    <>
                        <ProductIcon productType={row.values.type} />
                        <button>{row.values.name}</button>
                        <img src={arrow} alt='name-arrow' className='arrow-separator' />
                        <ReactTooltip id='row-name-tip' className='custom-color-no-arrow' delayHide={1000}
                            textColor='#FFF' backgroundColor='#231F20' effect='solid' />
                    </>
                )
            },
            {
                Header: "Domain",
                accessor: "domain"
            },
            {
                Header: "Name",
                accessor: "name",
            },
            {
                Header: "Date Updated",
                accessor: "modifiedOn",
                filterLabel: "Date Updated",
                Cell: ({ row }) => (
                    <>
                        <p className='cell--title'>Date Updated:</p>
                        {row.values.modifiedOn}
                    </>
                )
            },
            {
                Header: "Updated By",
                accessor: "modifiedBy",
                filterLabel: "Updated By",
                Cell: ({ row }) => (
                    <>
                        <p className='cell--title'>Updated By:</p>
                        {row.values.modifiedBy}
                    </>
                )
            },
            {
                Header: "Type Name",
                accessor: "type",
                filterLabel: "Product Type",
                Cell: ({ row }) => (
                    <>
                        <p className='cell--title'>Type:</p>
                        {row.values.type}
                    </>
                )
            },
            {
                Header: "",
                accessor: "allowAll",
                filterLabel: "Access",
                Cell: ({ row }) => (
                    <>
                        <div className='access-origin-separate'>
                            <p className='cell--title'>Allow all:</p>

                            <div>
                                {row.values.allowAll ?
                                    <HasAccessFlat 
                                        alt={row.values.allowAll === true ? 'Has Access' : 'No Access'}
                                        data-for='row-name-tip'
                                        data-tip={(row.values.allowAll === true ? 'You have access to view this data product.' : 'You are currently restricted from this data product and must request access to view it.')}
                                        className='typeIcon'/>
                                    : <HasNoAccessFlat alt={row.values.allowAll === true ? 'Has Access' : 'No Access'}
                                        data-for='row-name-tip'
                                        data-tip={(row.values.allowAll === true ? 'You have access to view this data product.' : 'You are currently restricted from this data product and must request access to view it.')}
                                        className='typeIcon'/>
                                }
                                <p className={row.values.allowAll === true ? 'green' : ''}>{row.values.allowAll === true ? 'Authorized' : 'Restricted'}</p>
                            </div>
                        </div>
                    </>
                )
            },
            {
                Header:"Delete",
                accessor:"deleteRecord",
                Cell: ({ row }) => (
                    <>
                        {row.values.name ==="Data Science Product" || row.values.name==="Table" ? 
                        <div className='default-record'>
                        <Button
                                id={`permission-record-default-btn`}
                                className='btn--rounded'
                            >
                                Default Record
                        </Button>
                    </div>
                    :
                    <div className='delete-record'>
                        <Button
                            id={`permission-record-delete-btn`}
                            className='btn--rounded btn--red-light'
                            onClick={() => handleClickDeleteModalConfirmation(row.original.id)}
                            disabled={isLoading}
                        >
                            Delete Record
                        </Button>
                    </div>}
                    </>
                )
            }
        ]
    )

    const data = useMemo(() => [...results], [results]);

    function errorResult() {
        return (
            <div data-testid='record-page-error' onClick={() => LoadData()} className='loading-error'>
                <span>{loadError}<br />
                    Click here to retry</span>
            </div>
        );
    }

    function loadingResult() {
        return (
            <div className="loading-spinner">
                <Grid color="#999999" height={100} width={110} ariaLabel='loading' />
                <span>Loading....</span>
            </div>
        );
    }

    function successfulResult() {
        return (
            <>
                <div className='result'>
                    <p>We found {resultsCount} results</p>
                </div>
                <Button id={`${props.id}-add-record`} className='btn btn-record btn--rounded btn--blue-light add-a-record-btn' onClick={handleClickAddRecord}>
                    Add Record
                </Button>
                <StatefulDatatable
                    pageName='record'
                    id='record-page-datatable'
                    data-testid='record-page-datatable'
                    columns={columns}
                    data={data}
                    hiddenColumns={['id',]}
                    sortOptions={['Default', 'Alphabetical A-Z', 'Alphabetical Z-A']}
                    onChangeSort={handleSortChange}
                    defaultPageSize={10}
                    defaultSortBy={[{ id: "id", desc: false }]}
                    onRowClick={(row) => {
                        handleRowClick(row);
                        sendEvent('analyticsClickAction', 'search_openReport', null, null, row.original);
                    }}
                    setResultsCount={setResultsCount}
                    displayCheck={false} />
                <PermissionRecord
                    id={`permissions-settings-${formType}`}
                    record={clickedItem}
                    isOpen={showUpdateRecordModal}
                    formType={formType}
                    handleClickDelete={handleClickDeleteModalConfirmation}
                    handleClose={handleClickCloseModal}
                    header={formType === 'ADD' ? 'Add a Record' : 'Edit a Record'} 
                    handleSave={UpdateRecord}
                />
                <ConfirmRecordDeleteModal
                    isOpen={showConfirmDeleteRecordModal}
                    id={'record-page-delete-modal'}
                    handleDelete={handleClickDeleteRecord}
                    handleClose={handleClickDeleteModalCancel}
                    header='Confirmation'
                    message={`Are you sure you want to delete ${clickedItem.name} from your permissions?`}
                />
            </>
        );
    }

    return (
        <section data-testid='permission-settings-page'>
            <div className='content--container permission-settings--container'>
                <div className="header">
                    <div className='header--title'>
                        <h1>Marketplace Permission Settings</h1>
                        <h2 className='long'>Update or add records to configure which user groups have access to specific items in the Data Marketplace</h2>
                        <h2 className='short'>Update or add records to configure user groups</h2>
                    </div>
                </div>
                    {isLoading && loadingResult()}
                    {loadError && loadError.length > 0 && errorResult()}
                    {!isLoading && loadError?.length === 0 ? successfulResult() : null}
            </div>
        </section>
    );
}

PermissionSettingsPage.propTypes = {
    id: PropTypes.string
};